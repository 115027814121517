import React, { useState, useEffect } from "react";
import CareersExperienced from "./CareersExperienced";
import HeaderBanner from "../common/HeaderBanner";
import CareersFreshers from "./CareersFreshers";

export default function Hiring() {
  const [candidate, setCandidate] = useState();
  const [def, setDef] = useState(true);

  // useEffect(() => {
  //   if (def) {
  //     setCandidate("fresher");
  //     document.querySelector("#checked").checked = true;
  //   }
  // }, []);

  const candidateExp = (e) => {
    setCandidate(e.target.value);
    setDef(false);
  };

  return (
    <>
      <HeaderBanner pageName="Careers" />

      <div className="contact-form-area pt-25 mt-30 pb-70">
        <div className="container">
          <div
            className="contact-info mr-20 card"
            style={{ backgroundColor: "#fff" }}
          >
            <h2 className="primary pb-5">
              We are hiring Software Trainee Engineer
            </h2>
            {/* <h2 className="text-dark mb-2">
              BE A PART OF THE ATJOIN PVT. LTD.
            </h2> */}
            {/* <p className="justify-both text-dark" id="career-info">Do you have in you what it takes to be a member of our Team? If you think you can spill out creativity on a page the way a client asks for, are dutifully professional & are enthusiastic about learning something new every day, then this is the league you would want to be a part of. Walk in & do your bit in steering Siliguri towards steady digital advancement. From here, the only way to move is forward.</p> */}

            <ul>
              <li>
                <div className="content">
                  <div className="career-number">
                    <strong>0</strong>
                  </div>
                  <h3 className="pt-2 text-dark">I'm</h3>
                </div>
                <div className="row">
                  <div className="col-sm-6 col-md-6 col-lg-6">
                    <ul>
                      <li>
                        <label className="text-dark">
                          <input
                            id="checked"
                            type="radio"
                            name="fe"
                            value="fresher"
                            className="rcheck mt-3"
                            onChange={candidateExp}
                          />{" "}
                          Fresher / Appeared (BCS | BCA | MCA | MCS | BE)
                        </label>
                      </li>
                    </ul>
                  </div>
                  <div className="col-sm-6 col-md-6 col-lg-6">
                    <ul>
                      <li>
                        <label className="text-dark">
                          <input
                            type="radio"
                            name="fe"
                            value="experienced"
                            className="rcheck mt-3"
                            onChange={candidateExp}
                          />{" "}
                          Experienced
                        </label>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
            </ul>

            {candidate && candidate === "fresher" ? (
              <CareersFreshers />
            ) : (
              candidate && candidate === "experienced" && <CareersExperienced />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
