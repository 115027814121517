import React from 'react'
import HeaderBanner from '../common/HeaderBanner'

function TermsAndConditions() {
  return (
    <>
      <HeaderBanner pageName='Terms of Service' />
      <div className="container">
        <div className="my-5 justify-both">
          <p>The following terms and conditions will be considered to have been acknowledged by the User on usage of the website www.atjoin.in. You are appealed to read them cautiously prior to you use the services of this site.</p>
          <p>The term User shall consign to the user who is surfing the site. The term Site refers to <a href="https://atjoin.in/">www.atjoin.in</a> owned and monitored by
            {" "}<b>ATJOIN PVT. LTD.</b>
          </p>
          <p>By utilizing the Site, you consent to follow and be bound by the following terms and conditions concerning your use of the Site. ATJOIN PVT. LTD. may revise the Terms of Use at any time without notice to you. Areas of the Site may have diverse terms of use posted. If there is a disagreement between the Terms of Use and terms of use posted for a specific area of the Site, the latter shall have priority with respect to your use of that area of the Site.</p>
          <p>We may cease User’s right of entry at any time for any reason. The provisions regarding to provision of warranty, accuracy of information and indemnification shall survive such termination. ATJOIN PVT. LTD. may supervise access to the Site.</p>
          <p>The text and content current on this site is the ATJOIN PVT. LTD. property of ATJOIN PVT. LTD. The software, text, images, graphics, video and audio used on this site belong to ATJOIN PVT. LTD. We have the copyrights material from this site may not be copied, modified, reproduced, republished, uploaded, transmitted, posted or distributed in any form without prior written permission from ATJOIN PVT. LTD. All rights not expressly granted herein are reserved. Illegal use of the resources appearing on this site may breach copyright, trademark and other applicable laws, and could result in criminal or civil penalties. ATJOIN PVT. LTD. is a registered trademark of ATJOIN PVT. LTD. This trademark may not be used in any style without prior written consent from ATJOIN PVT. LTD.</p>
          <p>ATJOIN PVT. LTD. solutions and services are continuous and published information may not be up to date. It is important to check the current position with ATJOIN PVT. LTD.</p>
          <p>The Site, and all text, material, information, software, merchandise and services offered on the Site, are provided on a “present” and “available” at ATJOIN PVT. LTD. ATJOIN PVT. LTD. specifically renounces all warranties of any type, whether state or implicit, counting, but not limited to, the implied warranties of merchantability, health for a particular purpose and non-infringement.</p>
          <p>ATJOIN PVT. LTD. contain no accountability for any damage to User’s computer system or loss of data that results from the download of any content, materials and information from the Site.</p>
          <p>ATJOIN PVT. LTD. can change or cease any aspect of its website at any point, including, its content or description. We reserve the right to change the terms and conditions applicable to use of the Site. Such changes shall be effective immediately upon notice, which shall be placed on the Site.</p>
          <p>ATJOIN PVT. LTD. info makes no pledge that: (a) the Site will meet your requirements; (b) the Site will be obtainable on an incessant, timely, safe, or error-free basis; (c) the results that may be obtained from the use of the Site or any services obtainable throughout the Site will be accurate or reliable.</p>
          <p>ATJOIN PVT. LTD. will not be liable for damages of any kind, counting without limitation, direct, incidental or significant damages (including, but not limited to, damages for lost profits, business interruption and loss of programs or information) arising out of the use of or inability to use Our website, or any information provided on the website, or in the Products any claim attributable to errors, omissions or other inaccuracies in the Product or interpretations thereof. Some authorities and jurisdiction do not allow the limitation or exclusion of liability. Accordingly, some of the above limitations may not apply to the User.</p>
          <h3 className="subscp-title txt22u">Intention of the website</h3>
          <p>All the resources enclosed in the Company’s web site are provided for informational reason only and shall not be interpreted as a commercial offer, a license, an advisory, fiduciary or professional relationship between you and the Company. No information presented on this site shall be considered an alternative for your self-regulating investigation.</p>
          <p>The information provided on this web site may be associated to products or services that are not available or may be are at higher rates in your country.</p>
          <h3 className="subscp-title txt22u">Intellectual property</h3>
          <p>This web site is protected by intellectual property rights and is the exclusive property of the Company. Any material that it contains, including, but not limited to, texts, data, graphics, pictures, sounds, videos, logos, icons or html code is protected under intellectual property law and remains property of the Company or third party. </p>
          <p className="cpolicy-txt">You may use this material for personal and non-commercial purposes in accordance with the principles governing intellectual property law. Any other use or modification of the content of the Company’s web site without the Company’s prior written authorization is prohibited.</p>
        </div>
      </div>
    </>
  )
}

export default TermsAndConditions