import React from "react";
import { Link } from "react-router-dom";
import HeaderBanner from "../common/HeaderBanner";

export default function Products() {
  const caseStudy = [
    {
      title: "EduMaster",
      desc1: "Education",
      desc2: "Exam",
      image: "assets/images/case-study/edumaster.png",
      path: "/edumaster",
    },
    {
      title: "Inspire Business",
      desc1: "Business",
      desc2: "Marketing",
      image: "assets/images/case-study/ib.png",
      path: "/inspire-business",
    },
    {
      title: "ATBELOVED",
      desc1: "Family",
      desc2: "Connective",
      image: "assets/images/case-study/atbeloved.png",
      path: "/atbeloved",
    },
    {
      title: "ATOPD",
      desc1: "Health",
      desc2: "Medical",
      image: "assets/images/case-study/atopd.png",
      path: "/atopd",
    },
    {
      title: "Matrimony",
      desc1: "Social Work",
      desc2: "Help",
      image: "assets/images/case-study/mkvlvivah.png",
      path: "/matrimony",
    },
    {
      title: "LeaveDesk - Leave Management System",
      desc1: "Educational",
      desc2: "Office",
      image: "assets/images/case-study/lms.png",
      path: "/lms",
    },
    {
      title: "eCommerce Solution",
      desc1: "Business",
      desc2: "Marketing",
      image: "assets/images/case-study/ecom.jpg",
      path: "/ecom",
    },
    {
      title: "BnB Turf",
      desc1: "Games",
      desc2: "Indoor & Outdoor Games",
      image: "assets/images/case-study/turf.jpg",
      path: "/turf",
    },
    {
      title: "Sheleads 100",
      desc1: "Business",
      desc2: "Multivendor Ecommerce",
      image: "assets/images/case-study/sheleads.png",
      path: "/sheleads",
    },
    {
      title: "Shiv Aaradhya Sevabhavi Bahuddeshiy Pratishthan",
      desc1: "Social Work",
      desc2: "Help",
      image: "assets/images/case-study/lingayat.png",
      path: "/lingayat-vadhu-var",
    },
  ];
  return (
    <>
      <HeaderBanner pageName="Products" />
      <section className="services-widget-area pt-100 pb-70">
        <div className="container">
          <div className="section-title text-center">
            <span className="primary">Our Products</span>
            <h2>Introduce Our Projects and Check Recent Work</h2>
          </div>
          <div className="row justify-content-center pt-45">
            {caseStudy &&
              caseStudy.map((item, i) => (
                <div className="col-lg-4 col-sm-6" key={i}>
                  <div className="case-study-item">
                    <Link to={item.path}>
                      <img src={item.image} alt="Images" />
                    </Link>
                    <div className="content">
                      <h3>
                        <Link to={item.path}>{item.title}</Link>
                      </h3>
                      <ul>
                        <li>
                          <Link to={item.path}>{item.desc1}</Link>
                        </li>
                        <li>
                          <Link to={item.path}>{item.desc2}</Link>
                        </li>
                      </ul>
                      <Link
                        to={`/cart${item.path}`}
                        className="more-btn d-flex align-items-center"
                      >
                        <span className="mb-0 fw-bold">Buy Now</span>
                        <i className="bx bx-right-arrow-alt ms-2"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </section>
    </>
  );
}
