import React from "react";
import DetailSideBar from "../common/DetailSideBar";
import HeaderBanner from "../common/HeaderBanner";

export default function WebDevelopment() {
  return (
    <>
      <HeaderBanner pageName="Web Development" />

      <div className="service-details-area pt-100 pb-70">
        <div className="container">
          <div className="row justify-both">
            <div className="col-lg-8">
              <div className="service-article">
                <div className="service-article-img">
                  <img src="assets/images/services/WebDevelopment/banner.png" alt="Images" className="w-100" />
                </div>
                <div className="service-article-content">
                  <h2>Web Development</h2>
                  <p>Web development is the building and maintenance of websites; it’s the work that happens behind the scenes to make a
                    website look great, work fast and perform well with a seamless user experience. Web developers, or ‘devs’, do this
                    by using a variety of coding languages. The languages they use depends on the types of tasks they are preforming and
                    the platforms on which they are working. Web development skills are in high demand worldwide and well paid too –
                    making development a great career option. It is one of the easiest accessible higher paid fields as you do not need a
                    traditional university degree to become qualified.</p>
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <ul className="service-article-list service-article-rs">
                        <li><i className="bx bxs-check-circle"></i>Saves Cost and Time</li>
                        <li><i className="bx bxs-check-circle"></i>Search Engine Optimization (SEO)</li>
                        <li><i className="bx bxs-check-circle"></i>Adapted web design</li>
                      </ul>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <ul className="service-article-list">
                        <li><i className="bx bxs-check-circle"></i>Higher sales conversion</li>
                        <li><i className="bx bxs-check-circle"></i>More security and reliable control</li>
                        <li><i className="bx bxs-check-circle"></i>Better performance</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="service-article-another">
                  <h2>What Benefit You Will Get</h2>
                  <p>Conventionally, an enterprise was hosted in a brick and mortar structure. However, times have changed as more
                    services have become digital. It is for this reason that entrepreneurs are encouraged to develop superb websites. In
                    this platform, it is quite easy to maintain a good rapport with the end user. This entails retrieving feedback on the
                    services and products offered. So you can interact with them and provide vital responses to the queries asked.
                    Additionally, there is no limitation on the time of activity. By automating the services on the website, clients
                    are guaranteed of round the clock services.</p>
                  <div className="row">
                    <div className="col-lg-6 col-sm-6">
                      <div className="service-article-another-img">
                        <img src="assets/images/services/WebDevelopment/02.png" alt="Images" className="p-img" />
                      </div>
                    </div>
                    <div className="col-lg-6 col-sm-6">
                      <div className="service-article-another-img">
                        <img src="assets/images/services/WebDevelopment/01.jpg" alt="Images" className="p-img" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="service-work-process">
                  <h2>Our Working Process</h2>
                  <p>When you think of building a website, your thoughts rotate around two main issues – price and time. These two values
                    depend largely on the size and scope of the project. To outline the whole development process, you can create a
                    website development timeline, adding tasks, and establishing milestones for your project. It is the best way to track
                    your project implementation to make sure you keep up with the deadline.</p>
                </div>
              </div>
            </div>
            <DetailSideBar data='service' />
          </div>
        </div>
      </div>
    </>
  );
}
