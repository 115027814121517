import React from 'react';
import './App.css';
import Router from './routers/Router';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'

function App() {
  return (
    <Router />
  );
}

export default App;
