import React from "react";
import DetailSideBar from "../common/DetailSideBar";
import HeaderBanner from "../common/HeaderBanner";

export default function BusinessReform() {
  return (
    <>
      <HeaderBanner pageName="Business Reform" />

      <div className="service-details-area pt-100 pb-70">
        <div className="container">
          <div className="row justify-both">
            <div className="col-lg-8">
              <div className="service-article">
                <div className="service-article-img">
                  <img src="assets/images/services/BusinessReform/banner.jpg" alt="Images" className="w-100" />
                </div>
                <div className="service-article-content">
                  <h2>Business Reform</h2>
                  <p>As the name suggests, business environment reform is about modifying the business environment. This is done to
                    positively influence a change in the behavior of private enterprises in ways that lead to increased levels of
                    investment and innovation and the creation of more and better jobs. There are three objectives:
                    <ul type="circle">
                      <li>
                        <strong> Reducing business costs: </strong>to increase profits (that may lead to increased investment) or
                        increasing market share (and thereby output and employment);
                      </li>
                      <li>
                        <strong>Reducing risks:</strong> poor or frequently changing government policies, laws and regulations pose
                        a risk for business, thus, reducing the cost of capital (and thereby increasing the number of attractive
                        investments in the market);{" "}
                      </li>
                      <li>
                        <strong>{" "}Increasing competitive pressures through new entry:</strong>{" "}
                        to stimulate the efficiency and innovating incentives in the market.
                      </li>
                    </ul>
                  </p>
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <ul className="service-article-list service-article-rs">
                        <li><i className="bx bxs-check-circle"></i>Simplifying business registration and licensing procedures</li>
                        <li><i className="bx bxs-check-circle"></i>Improving tax policies and administration</li>
                        <li><i className="bx bxs-check-circle"></i>Enabling better access to finance</li>
                      </ul>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <ul className="service-article-list">
                        <li><i className="bx bxs-check-circle"></i>Improving labour laws and administration</li>
                        <li><i className="bx bxs-check-circle"></i>Improving the overall quality of regulatory governance</li>
                        <li><i className="bx bxs-check-circle"></i>Improving access to market information</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="service-article-another">
                  <h2>What Benefit You Will Get</h2>
                  <p>Inappropriate regulation imposes costs on both the private sector and the government, reducing productivity and slowing
                    growth. Reforming the business enabling environment allows businesses to spend less time and money dealing with
                    regulations, instead focusing their energies on producing and marketing their goods. Government can spend fewer
                    resources regulating and more resources on public investment and providing social services.</p>
                  <div className="row">
                    <div className="col-lg-6 col-sm-6">
                      <div className="service-article-another-img">
                        <img src="assets/images/services/BusinessReform/img1.png" alt="Images" className="p-img" />
                      </div>
                    </div>
                    <div className="col-lg-6 col-sm-6">
                      <div className="service-article-another-img">
                        <img src="assets/images/services/BusinessReform/img2.jpg" alt="Images" className="p-img" />
                      </div>
                    </div>
                  </div>

                </div>
                <div className="service-work-process">
                  <h2>Our Working Process</h2>
                  <p>Although finding the best IT consulting services can be tricky, once you’ve found one, there are plenty of benefits
                    to working with them. IT consultants allow you to take advantage of their broad experience and skills, helping you
                    benefit from their knowledge in a number of ways. Working with consultants is also much easier than doing it all
                    yourself—and can save you time and money in many ways. If your company is ready to take the advantage of some expert
                    guidance in an area that seems intimidating or overwhelming, then consider hiring a software consulting company in India.</p>
                </div>
              </div>
            </div>
            <DetailSideBar data='service' />
          </div>
        </div>
      </div>
    </>
  );
}
