import React from 'react'
import DetailSideBar from '../common/DetailSideBar'
import HeaderBanner from '../common/HeaderBanner'

export default function InspireBusiness() {
  return (
    <>
      <HeaderBanner pageName="LeaveDesk - Leave Management System" />

      <div className="service-details-area pt-100 pb-70">
        <div className="container">
          <div className="row justify-both">
            <div className="col-lg-8">
              <div className="service-article">
                <div className="service-article-img">
                  <a href="http://www.leavedesk.co.in" target='_blank' rel="noreferrer">
                    <img src="assets/images/case-study/lms.png" alt="Images" className="w-100" />
                  </a>
                </div>
                <div className="service-article-content">
                  <h2>LeaveDesk - Leave Management System</h2>
                  <h3>Why Go With LeaveDesk...?</h3>
                  <h5>Simple, Easy to Use, and Digital LeaveDesk for Any Business</h5>
                  <div className="col-lg-12 col-md-12">
                    <ul className="service-article-list">
                      <li><i className="bx bxs-check-circle"></i>Simple:<br />
                        <p>With an LMS, employees can submit leave requests electronically, minimizing errors and ensuring accurate data entry.</p></li>
                      <li><i className="bx bxs-check-circle"></i>Easy to Use:<br />
                        <p>LMS provides a centralized platform where employees can submit their leave requests, and managers can review and approve them.</p></li>
                      <li><i className="bx bxs-check-circle"></i>Unlimited Employees:<br />
                        <p>LeaveDesk supports unlimited employees added by system or organization admin.</p></li>
                    </ul>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <ul className="service-article-list service-article-rs">
                        <li><i className="bx bxs-check-circle"></i>Simple</li>
                        <li><i className="bx bxs-check-circle"></i>Easy to Use</li>
                        <li><i className="bx bxs-check-circle"></i>Unlimited Employees</li>
                      </ul>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <ul className="service-article-list">
                        <li><i className="bx bxs-check-circle"></i>Time Saving</li>
                        <li><i className="bx bxs-check-circle"></i>Online Presence</li>
                        <li><i className="bx bxs-check-circle"></i>Offers Variety in Experience</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="service-article-another">
                  <h2>What Benefit You Will Get</h2>
                  <p>A LeaveDesk (LMS) offers several advantages for organizations and their employees....🤓</p>
                  <div className="service-article-content">
                    <div className="col-lg-12 col-md-12">
                      <ul className="service-article-list">
                        <li><i className="bx bxs-check-circle"></i>Streamlined Process:<br />
                          <p>An LMS automates the leave application, approval, and tracking processes. It eliminates the need for manual paperwork, reducing administrative work and streamlining the entire leave management process.</p></li>
                        <li><i className="bx bxs-check-circle"></i>Improved Accuracy:<br />
                          <p>With an LMS, employees can submit leave requests electronically, minimizing errors and ensuring accurate data entry. The system also calculates leave balances automatically, reducing the risk of miscalculations or discrepancies.</p></li>
                        <li><i className="bx bxs-check-circle"></i>Enhanced Efficiency:<br />
                          <p>LMS provides a centralized platform where employees can submit their leave requests, and managers can review and approve them. It saves time for both employees and managers, as they can access the system anytime, anywhere, without the need for physical paperwork or face-to-face meetings.</p></li>
                        <li><i className="bx bxs-check-circle"></i>Transparent and Fair:<br />
                          <p>The LMS promotes transparency by providing clear guidelines and policies regarding leave entitlements, accruals, and usage. It ensures fair and consistent application of leave policies across the organization, preventing any favoritism or bias.</p></li>
                        <li><i className="bx bxs-check-circle"></i>Accurate Leave Tracking:<br />
                          <p>The LMS maintains a comprehensive record of employee leave, including the types of leave taken (such as sick leave, vacation, or personal leave) and the duration of each leave. It allows managers to track and monitor leave usage effectively, ensuring appropriate staffing levels and avoiding scheduling conflicts.</p></li>
                        <li><i className="bx bxs-check-circle"></i>Improved Compliance:<br />
                          <p>An LMS helps organizations comply with labor laws and regulations related to leave entitlements, such as annual leave, maternity leave, or family medical leave. It assists in tracking and managing different types of leave, ensuring legal compliance and preventing any potential penalties or legal issues.</p></li>
                        <li><i className="bx bxs-check-circle"></i>Employee Self-Service:<br />
                          <p>LMS often includes a self-service portal where employees can access their leave balances, request leave, view leave history, and check the status of their applications. This empowers employees by giving them control over their leave management and reducing dependency on HR personnel.</p></li>
                        <li><i className="bx bxs-check-circle"></i>Data Analysis and Reporting:<br />
                          <p>An LMS generates reports and analytics related to leave usage, trends, and patterns. This data can be utilized for decision-making, resource planning, and identifying any recurring leave-related issues or patterns within the organization.</p></li>
                      </ul>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 col-sm-6">
                      <div className="service-article-another-img">
                        <img src="assets/images/products/lms/lms-1.png" alt="Images" className='p-img' />
                      </div>
                    </div>
                    <div className="col-lg-6 col-sm-6">
                      <div className="service-article-another-img">
                        <img src="assets/images/products/lms/lms-2.png" alt="Images" className='p-img' />
                      </div>
                    </div>
                  </div>
                  <p>A LeaveDesk offers numerous advantages, including process efficiency, cost savings, accuracy, transparency, compliance, employee empowerment, and data-driven decision-making. Implementing an LMS can greatly benefit organizations by streamlining leave management and improving overall operational effectiveness.</p>
                </div>
                <div className="service-work-process">
                  <h2>Our Working Process</h2>
                  <p>Overall, the working process of a LeaveDesk involves streamlining the leave request and approval process, automating leave calculations, facilitating communication between employees and managers, and providing comprehensive leave tracking and reporting capabilities. It aims to simplify leave management tasks, improve accuracy, and enhance overall efficiency within an organization.</p>
                </div>
              </div>
            </div>
            <DetailSideBar data='lms' />
          </div>
        </div>
      </div>
    </>
  )
}
