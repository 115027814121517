import React from "react";
import { Outlet } from "react-router-dom";
import Footer from "../common/Footer";
import Header from "../common/Header";
import HeaderMenu from "../common/HeaderMenu";
import useScript from "../script/ScriptLoader";

export default function Site() {
  useScript("./assets/js/jquery.min.js");
  useScript("./assets/js/bootstrap.bundle.min.js");
  useScript("./assets/js/owl.carousel.min.js");
  useScript("./assets/js/jquery.magnific-popup.min.js");
  useScript("./assets/js/jquery.nice-select.min.js");
  useScript("./assets/js/wow.min.js");
  useScript("./assets/js/meanmenu.js");
  useScript("./assets/js/jquery.ajaxchimp.min.js");
  useScript("./assets/js/form-validator.min.js");
  useScript("./assets/js/contact-form-script.js");
  useScript("./assets/js/custom.js");
  return (
    <>
      <Header />
      <HeaderMenu />
      <Outlet />
      <Footer />
    </>
  );
}
