import React from 'react'
import DetailSideBar from '../common/DetailSideBar'
import HeaderBanner from '../common/HeaderBanner'

export default function AtOpd() {
  return (
    <>
      <HeaderBanner pageName="ATOPD" />

      <div className="service-details-area pt-100 pb-70">
        <div className="container">
          <div className="row justify-both">
            <div className="col-lg-8">
              <div className="service-article">
                <div className="service-article-img">
                  <a href="https://atopd.in" target='_blank' rel="noreferrer">
                    <img src="assets/images/case-study/atopd.png" alt="Images" className="w-100" />
                  </a>
                </div>
                <div className="service-article-content">
                  <h2>ATOPD</h2>
                  <p>ATOPD is complete software solution for computerized record keeping and data processing of OPD management in Hospitals. ATOPD is a complete hospital information system for Outpatients registration, treatments, billing and reporting with details of patient's information.</p>
                </div>
                <div className="service-article-another">
                  <h2>What Benefit You Will Get</h2>
                  <p>OPD management was never so easy before using OPD Management Software by ATOPD System. Forget about writing prescription and maintaining patient data manually. This software take care of all your need for OPD management. This OPD Management Software has many features as mention below. Also it can be slightly customize according to your need.</p>
                  <div className="service-article-content">
                    <div className="row">
                      <div className="col-lg12 col-md-12">
                        <ul className="service-article-list service-article-rs">
                          <li><i className="bx bxs-check-circle"></i>Saves Time</li>
                          <li><i className="bx bxs-check-circle"></i>Data of Patient like Name, Reg. No., Gender, Age, Weight, Pulse, B.P. etc. can be store</li>
                          <li><i className="bx bxs-check-circle"></i>Information for patients like Complaint, History, Clinical Details, OPD Procedure, Investigation, Diagnosis, Allergy etc. can be pickup</li>
                          <li><i className="bx bxs-check-circle"></i>Prescription from Pre defined templates and modifies them as per our requirement.</li>
                          <li><i className="bx bxs-check-circle"></i>Prescription in Multi languages.</li>
                          <li><i className="bx bxs-check-circle"></i>Next Visit Date from Prescription.</li>
                          <li><i className="bx bxs-check-circle"></i>Advise and Suggestion.</li>
                          <li><i className="bx bxs-check-circle"></i>Attach other Reports.</li>
                          <li><i className="bx bxs-check-circle"></i>Fees Details.</li>
                          <li><i className="bx bxs-check-circle"></i>Medical Certificate and Fitness Certificate.</li>
                          <li><i className="bx bxs-check-circle"></i>Store all data of patient from first consultation.</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 col-sm-6">
                      <div className="service-article-another-img">
                        <img src="assets/images/products/atopd/atopd-1.jpg" alt="Images" className='p-img' />
                      </div>
                    </div>
                    <div className="col-lg-6 col-sm-6">
                      <div className="service-article-another-img">
                        <img src="assets/images/products/atopd/atopd-2.jpg" alt="Images" className='p-img' />
                      </div>
                    </div>
                  </div>
                  <p>To enable Doctors manage their OPD efficiently with the help of technology solutions at affordable cost.</p>
                </div>
                <div className="service-work-process">
                  <h2>Our Working Process</h2>
                  <p>ATOPD Clinic Software is an easy to use, affordable and powerful Software for Doctors to manage clinic at their finger tips. A useful clinic-software for Patient Management, Clinic Management, OPD Management with cloud backup to save your important data. A Practice management software to manage your Electronic Medical Records (EMR) at ease. A complete software solution for your OPD with top notch support team to keep you working without any hiccups. Enjoy the peace of mind with ATOPD, a clinic-software that keeps on evolving as the times change.</p>
                </div>
              </div>
            </div>
            <DetailSideBar data='product' />
          </div>
        </div>
      </div>
    </>
  )
}
