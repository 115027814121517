import React from 'react';
import HeaderBanner from '../common/HeaderBanner';

export default function Portfolio() {

  const imgPre = 'assets/images/portfolio/';
  const pfObj = [
    { img: imgPre + '1.jpg', title: 'Global Business School & Research Centre, Pune', desc: '(Training on MSSQL)' },
    { img: imgPre + '2.jpg', title: 'D. Y. Patil College, Pune.', desc: '(Training on Python)' },
    { img: imgPre + '3.jpg', title: 'D. Y. Patil College, Pune.', desc: '(Training on Career Guidance)' },
    { img: imgPre + '4.jpg', title: 'Industry Expert Lecture', desc: '(Training on Dot NET)' },
    { img: imgPre + '5.jpg', title: 'Flame University SEO, SEM & GDN Bidding Techniques', desc: '' },
    { img: imgPre + '6.jpg', title: 'Guru Gobind Singh Polytechnic, Nashik', desc: '(Training on UI/UX)' },
    { img: imgPre + '7.jpg', title: 'SND Polytechnic Students Visited Us', desc: '(Industrial Visit)' },
    { img: imgPre + '8.jpg', title: 'Agasti College of Management, Sangamner', desc: '(Career Opportunities in IT)' },
    { img: imgPre + '9.jpg', title: 'Pool Campus at Adsul', desc: '' },
    { img: imgPre + '10.png', title: 'FDP at SND Polytechnic, Yeola', desc: 'Interacted with 100+ diploma Faculties in FDP on "Emerging Trends in IT" at  SND Polytechnic, Yeola, Nashik.' },
  ]

  return (
    <>
      <HeaderBanner pageName='Portfolio' />

      <section className="services-widget-area pt-100 pb-70">
        <div className="container">
          <div className="section-title text-center">
            <span className="primary">Our Portfolio</span>
            <h2>Some of the Training Glimpses</h2>
          </div>
          <div className="row pt-45">

            {pfObj.map((item, index) => {
              return (
                <div className="col-lg-4 col-md-6" key={index}>
                  <div className="services-item">
                    <img src={item.img} alt="Images" className='border border-3 border-warning rounded-4' />
                    <h6 className='mt-4'>{item.title}</h6>
                    <p className='text-secondary mb-0'>{item.desc}</p>
                  </div>
                </div>
              )
            })}

            {/* <div className="col-lg-4 col-md-6">
              <div className="services-item">
                <img src="assets/images/portfolio/1.jpg" alt="Images" />
                <div className="content">
                <i className="flaticon-consultant"></i>
                <h6>Global Business School & Research Centre, Pune</h6>
                <p className='text-secondary mb-0'>(Training on MSSQL)</p>
                </div>
              </div>
            </div> */}

            {/* <div className="col-lg-12 col-md-12 text-center">
              <div className="pagination-area">
                <Link to="services-1.html" className="prev page-numbers">
                  <i className='bx bx-left-arrow-alt'></i>

                <span className="page-numbers current" aria-current="page">1</span>
                <Link to="services-1.html" className="page-numbers">2
                <Link to="services-1.html" className="page-numbers">3
                <Link to="services-1.html" className="next page-numbers">
                  <i className='bx bx-right-arrow-alt'></i>
              </div>
            </div> */}
          </div>
        </div>
      </section>

      <section className="clients-area pt-100 pb-70">
        <div className="container">
          <div className="section-title text-center">
            <span className="sp-color2 primary">Our Clients</span>
            <h2>We are Trusted by 45+ Organizations Worldwide</h2>
          </div>
        </div>
        <div className="client-circle">
          <div className="client-circle-1">
            <div className="circle"></div>
          </div>
          <div className="client-circle-2">
            <div className="circle"></div>
          </div>
          <div className="client-circle-3">
            <div className="circle"></div>
          </div>
          <div className="client-circle-4">
            <div className="circle"></div>
          </div>
          <div className="client-circle-5">
            <div className="circle"></div>
          </div>
          <div className="client-circle-6">
            <div className="circle"></div>
          </div>
          <div className="client-circle-7">
            <div className="circle"></div>
          </div>
        </div>
      </section>
    </>
  )
}
