import React, { useEffect, useState } from "react";
import Service from "../Services/Service";
import ExportToExcel from "../Services/ExportToExcel";
import moment from "moment";
import { Link } from "react-router-dom";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";

function DisplayAllFreshers() {
  const [freshers, setFreshers] = useState();

  useEffect(() => {
    fetchAllFreshers();
  }, []);

  const fetchAllFreshers = () => {
    Service.getAllFreshers().then((res) => {
      if (res && res.data) {
        setFreshers(res.data);
      }
    });
  };

  const downloadExcel = () => {
    var data = freshers;
    for (let i = 0; i < data.length; i++) {
      data[i].Resume = "https://atjoin-api.atopd.in/" + data[i].Resume;
    }
    ExportToExcel.exportToExcel(data, "Freshers Excel");
  };

  const logout = () => {
    localStorage.removeItem("isLoggedIn");
    document.location.replace("/");
  };

  return (
    <div className="hscroll">
      <div className="col-12 text-end career-btn-mt">
        <Link to="/view-all-internship">
          <button type="submit" className="btn btn-info mr-20 career-btn-mx">
            View Internship
          </button>
        </Link>
        <Link to="/view-all-experienced">
          <button type="submit" className="btn btn-primary mr-20 career-btn-mx">
            View Experienced
          </button>
        </Link>
        <button
          type="submit"
          className="btn btn-success mr-20 career-btn-mx"
          onClick={() => downloadExcel()}
        >
          Download Excel
        </button>
        <button
          type="submit"
          className="btn btn-primary mr-20 career-btn-mx"
          onClick={() => logout()}
        >
          Logout
        </button>
      </div>
      <div className="col-12 text-center mt-3">
        <h2>Freshers Candidates</h2>
      </div>
      <div>
        <Table>
          <Thead>
            <Tr className="text-center">
              <Th>Sr. No.</Th>
              <Th>Name</Th>
              <Th>Address</Th>
              <Th>Contact No.</Th>
              <Th>Email ID</Th>
              <Th>Applied For</Th>
              <Th>Duration</Th>
              <Th>Preferred Mode</Th>
              <Th>Qualification</Th>
              <Th>Percentage</Th>
              <Th>College Name</Th>
              <Th>University Name</Th>
              <Th>Applied On</Th>
              <Th>Resume</Th>
            </Tr>
          </Thead>
          <Tbody>
            {freshers &&
              freshers.map((fresher, i) => (
                <Tr key={i}>
                  <Td>{i + 1}</Td>
                  <Td>{fresher.Name}</Td>
                  <Td>{fresher.Address}</Td>
                  <Td>{fresher.Contact}</Td>
                  <Td>{fresher.Email}</Td>
                  <Td>{fresher.Post}</Td>
                  <Td>{fresher.Duration}</Td>
                  <Td>{fresher.PrefferedMode}</Td>
                  <Td>{fresher.Qualification}</Td>
                  <Td>{fresher.Percentage}</Td>
                  <Td>{fresher.CollegeName}</Td>
                  <Td>{fresher.UniversityName}</Td>
                  <Td>{fresher.Date}</Td>
                  <Td>
                    <a
                      href={"https://atjoin-api.atopd.in/" + fresher.Resume}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src="assets/images/download.png"
                        height={30}
                        width={30}
                        alt="Download"
                      />
                    </a>
                  </Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
      </div>
    </div>
  );
}

export default DisplayAllFreshers;
