import React from 'react'
import DetailSideBar from '../common/DetailSideBar'
import HeaderBanner from '../common/HeaderBanner'

export default function EduMaster() {
  return (
    <>
      <HeaderBanner pageName="EduMaster" />

      <div className="service-details-area pt-100 pb-70">
        <div className="container">
          <div className="row justify-both">
            <div className="col-lg-8">
              <div className="service-article">
                <div className="service-article-img">
                  <a href="https://edumaster.co.in" target='_blank' rel="noreferrer">
                    <img src="assets/images/case-study/edumaster.png" alt="Images" className="w-100" />
                  </a>
                </div>
                <div className="service-article-content">
                  <h2>EduMaster</h2>
                  <p>EduMaster is online platform for Colleges, Institutes, Students, Jobseekers & Companies to prepare online exams
                    & Conduct proctored examination.</p>
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <ul className="service-article-list service-article-rs">
                        <li><i className="bx bxs-check-circle"></i>Saves Cost and Time</li>
                        <li><i className="bx bxs-check-circle"></i>Enhances Focus on the Core Concepts</li>
                        <li><i className="bx bxs-check-circle"></i>Boosts Productivity</li>
                      </ul>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <ul className="service-article-list">
                        <li><i className="bx bxs-check-circle"></i>Updates you with the Latest Trends</li>
                        <li><i className="bx bxs-check-circle"></i>Dedicated dashboard</li>
                        <li><i className="bx bxs-check-circle"></i>Offers Variety in Experience</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="service-article-content">
                  <h2>What Benefit You Will Get</h2>
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <ul className="service-article-list">
                        <li><i className="bx bxs-check-circle"></i>Online Exam Preparation</li>
                        <li><i className="bx bxs-check-circle"></i>Conduct Proctored Examination</li>
                        <li><i className="bx bxs-check-circle"></i>Interview Preparation</li>
                      </ul>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <ul className="service-article-list">
                        <li><i className="bx bxs-check-circle"></i>Question Banks</li>
                        <li><i className="bx bxs-check-circle"></i>Full Customization</li>
                        <li><i className="bx bxs-check-circle"></i>Perfect Solution for Students, Institutes & Companies</li>
                      </ul>
                    </div>
                    <div className="col-lg-12 col-md-12">
                      <ul className="service-article-list">
                        <li><i className="bx bxs-check-circle"></i>Automation Of Planning And Scheduling:<br />
                          <p>EduMaster should be capable enough to deal with the messy planning phase. It should be able to collect information from students via its online portal and set up a schedule, process student applications, generate automated admit cards, allot exams according to the subject, and mark attendance on responses.</p></li>
                        <li><i className="bx bxs-check-circle"></i>Assessment Pattern Creation:<br />
                          <p>Assessment pattern creation is more of a technical term used for setting up subject-wise question papers. EduMaster should be able to process various question types, based on marks, and categorize them according to parameters set by individual institutes.</p></li>
                        <li><i className="bx bxs-check-circle"></i>Question Bank Management:<br />
                          <p>During the preparatory phases for exams, the EduMaster should provide question banks, suggestion papers, and reference links or PDFs on various subjects. The question banks should be interactive in nature with proper multimedia support and text editors. The mode of presentation should be appealing, enough to draw the attention of individual students.</p></li>
                        <li><i className="bx bxs-check-circle"></i>Configuration Of Questions:<br />
                          <p>The EduMaster must be capable enough to help teachers set up multiple question patterns for any subject of their choice. Thus, including questions like multiple-choice, open-ended, quizzes, and more.</p></li>
                        <li><i className="bx bxs-check-circle"></i>Assessment Of Integrated Rubrics:<br />
                          <p>Rubrics are effective and efficient tools for assessing student performance. Rubrics ensure knowledge is imparted better than conventional methods. Rubrics also promote feedback and mutual interaction between a student and teacher.</p></li>
                        <li><i className="bx bxs-check-circle"></i>Multi-Language Support:<br />
                          <p>Say yes to the one which supports multiple languages, making it easy for you and your students to get through the process of online examinations in a hassle-free manner.</p></li>
                        <li><i className="bx bxs-check-circle"></i>Custom Test-Taking Options:<br />
                          <p>This process helps educators customize test-taking methods that are suitable for individual students. Thus, helping institutes make exams more interactive and unique in their own way.</p></li>
                        <li><i className="bx bxs-check-circle"></i>Instant Notifications:<br />
                          <p>The EduMaster should be able to provide instant notification of exams, assessments, classes, timing, and projects to students and parents, with great precision and without leaking personal information.</p></li>
                        <li><i className="bx bxs-check-circle"></i>Configurable Roles And Permissions:<br />
                          <p>EduMaster should be able to provide proper access and set up verified roles, ensuring that individual admins and allotted teachers have full control. This way institutions can monitor the accessibility levels of individual tests and get the results published in an unbiased way.</p></li>
                        <li><i className="bx bxs-check-circle"></i>Online Proctoring:<br />
                          <p>Look for EduMaster that provides proper methods of surveillance over individual students. It must be able to detect misconduct showcased by individual students and send timely notifications to the controller.</p></li>
                        <li><i className="bx bxs-check-circle"></i>Reports And Dashboard:<br />
                          <p>It should have the ability to produce different kinds of real-time reports for institutions.The method of representation plays a key factor, as it is seen that multimedia presentations turn out to be more appealing to individual users.So, EduMaster that provides multimedia-based dashboard reports has a greater advantage over its counterparts.</p></li>
                        <li><i className="bx bxs-check-circle"></i>Automated Evaluation:<br />
                          <p>While going through papers submitted by individual students, teachers might be biased at times.Always look out for EduMaster involving various security stages, evaluator handling processes, and click-by-click audit mechanism</p></li>
                      </ul>
                    </div>
                  </div>
                  <div className="service-article-another">
                    <div className="row">
                      <div className="col-lg-6 col-sm-6">
                        <div className="service-article-another-img">
                          <img src="assets/images/products/edumaster/edumaster-1.jpg" alt="Images" className='p-img' />
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-6">
                        <div className="service-article-another-img">
                          <img src="assets/images/products/edumaster/edumaster-2.jpg" alt="Images" className='p-img' />
                        </div>
                      </div>
                    </div>
                  </div>
                  <p>Considering the current scenario, where frequent lockdowns and social distancing are the new normal, educational institutions have moved toward an automated path of digital classrooms and lectures. Similar methods are applied when it comes to conducting exams.</p>
                </div>
                <div className="service-work-process">
                  <h2>Our Working Process</h2>
                  <p>EduMaster is the future of academic assessments, providing various improvements over conventional
                    methods with their automated systems and robust processing skills. The upcoming days will ensure
                    greater developments and hassle-free operative experiences.</p>
                </div>
              </div>
            </div>
            <DetailSideBar data='product' />
          </div>
        </div>
      </div>
    </>
  )
}
