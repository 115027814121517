import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import Service from "../Services/Service";
import moment from "moment";


const getVid = () => {
  const vid = JSON.parse(localStorage.getItem("vacancyData"));
  return vid;
};

export default function ApplyNow() {
  // const [applyData, setApplyData] = useState();
  const vacancyData = getVid();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm();

  useEffect(() => {
    loadApplyData();
  }, []);

  //load vacancy data
  const loadApplyData = () => {
    Service.getVacancy().then((res) => {
      // setApplyData(res.data);
    });
  };

  //savedata apply job data
  const saveData = (data) => {
    let fd = new FormData();
    fd.append("VId", vacancyData.VId);
    fd.append("Name", data.Name);
    fd.append("Email", data.Email);
    fd.append("Phone", data.Phone);
    fd.append("Resume", data.Resume[0]);
    Service.applyJob(fd).then((res) => {
      alert("data saved successfully...!");
      reset();
      loadApplyData();
    });
  };
  return (
    <>
      <div className="inner-banner">
        <div className="container">
          <div className="inner-title text-center">
            <h3>Apply Job</h3>
            <ul><li><Link to="/">Home</Link></li><li><i className="bx bx-chevrons-right"></i></li><li>Apply Job</li></ul>
          </div>
        </div>
        <div className="inner-shape"><img src="assets/images/shape/inner-shape.png" alt="Images" /></div>
      </div>
      <div className="container my-5">
        <div className="row">
          <div className="col-7 me-3">
            <div className="row inner-banner text-center text-light p-5">
              <div className="col-4 border-end">
                <i className="fa-solid fa-location-dot fa-xl"></i><br />
                <span className=""> {vacancyData && vacancyData.Location}</span><br />
                <span>MH.INDIA</span>
              </div>
              <div className="col-4 border-end">
                <i className="fa-solid fa-calendar-days fa-xl"></i><br />
                <span className="">Posted  <br />{vacancyData && moment(vacancyData.PostedDate).format("DD-MM-YYYY")}</span><br />
                <span>2900 USD</span>
              </div>
              <div className="col-4">
                <i className="fa-solid fa-wallet fa-xl"></i><br />
                <span className="">{vacancyData && vacancyData.JobType}</span><br />
                <span>2900 USD</span>
              </div>
            </div>
            <hr />
            <div className="Job-Desc">
              <ul type="circle">
                <li className="mb-3 border-bottom">
                  <strong>Location : </strong> {vacancyData && vacancyData.Location}
                </li>
                <li className="mb-3 border-bottom">
                  <strong>Salary : </strong> {vacancyData && vacancyData.Salary}
                </li>
                <li className="mb-3 border-bottom">
                  <strong>Experience :</strong> {vacancyData && vacancyData.Experiance}
                </li>
                <li className="mb-3 border-bottom">
                  <strong>Notice Period : </strong>{vacancyData && vacancyData.NoticePeriod}
                </li>
                <li className="mb-3 border-bottom">
                  <strong>Roles And Responsibilities :</strong>{vacancyData && vacancyData.JobDescription}
                </li>
              </ul>
            </div>
          </div>
          <div className="col-4 border  py-5" >
            <form onSubmit={handleSubmit(saveData)}>
              <h4 className="text-center mb-3">Apply now</h4>
              <div className="mb-3">
                <label htmlFor="name" className="form-label">Name</label>
                <input type="text" className="form-control" {...register("Name", { required: "Enter Full Name" })} />
                {errors.Name && (<span className="text-danger">{errors.Name.message}</span>)}
              </div>
              <div className="mb-3">
                <label htmlFor="email" className="form-label">Email</label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  aria-describedby="emailHelp"
                  {...register("Email", { required: "Enter Email ID" })} />
                {errors.Email && (<span className="text-danger">{errors.Email.message}</span>)}
              </div>
              <div className="mb-3">
                <label htmlFor="phone" className="form-label">
                  Phone
                </label>
                <input
                  type="number"
                  className="form-control"
                  {...register("Phone", {
                    required: "Enter Phone Number", minLength: { value: 10, message: "Enter only 10 digits" },
                    maxLength: { value: 10, message: "Enter only 10 digits" }
                  })} />
                {errors.Phone && (<span className="text-danger">{errors.Phone.message}</span>)}
              </div>
              <div className="mb-3">
                <input
                  type="file"
                  className="form-control"
                  {...register("Resume", {
                    required: "Upload Resume only pdf,doc file.",
                  })}
                  accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" />
                {errors.Resume && (<span className="text-danger">{errors.Resume.message}</span>)}
              </div>
              <button type="submit" className="btn btn-primary d-flex justify-content-center">
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
