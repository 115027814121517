import React from "react";
import DetailSideBar from "../common/DetailSideBar";
import HeaderBanner from "../common/HeaderBanner";

export default function LingayatVadhuVar() {
  return (
    <>
      <HeaderBanner pageName="Matrimony" />

      <div className="service-details-area pt-100 pb-70">
        <div className="container">
          <div className="row justify-both">
            <div className="col-lg-8">
              <div className="service-article">
                <div className="service-article-img">
                  <a
                    href="https://lingayatvadhuvar.in/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src="assets/images/case-study/lingayat.png"
                      alt="Images"
                      className="w-100"
                    />
                  </a>
                </div>
                <div className="service-article-content">
                  <h2>Matrimony</h2>
                  <p>
                    The main objective of Matrimonial Portal is to provide
                    grooms and brides an excellent matchmaking involvement
                    exploring the favorable circumstances and resources to meet
                    their true potential partners.
                  </p>
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <ul className="service-article-list service-article-rs">
                        <li>
                          <i className="bx bxs-check-circle"></i>Saves Cost and
                          Time
                        </li>
                        <li>
                          <i className="bx bxs-check-circle"></i>Enhances Focus
                          on the Core Business
                        </li>
                        <li>
                          <i className="bx bxs-check-circle"></i>Boosts
                          Productivity
                        </li>
                      </ul>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <ul className="service-article-list">
                        <li>
                          <i className="bx bxs-check-circle"></i>Updates you
                          with the Latest Trends
                        </li>
                        <li>
                          <i className="bx bxs-check-circle"></i>Passionate and
                          Dedicated Services
                        </li>
                        <li>
                          <i className="bx bxs-check-circle"></i>Offers Variety
                          in Experience
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="service-article-another">
                  <h2>What Benefit You Will Get</h2>
                  <p>
                    The matrimonial website has evolved into a lucrative
                    internet business, as individuals are increasingly active in
                    looking for their partners on the web! People from all
                    corners of the world are connecting via the internet at an
                    unprecedented rate, which has resulted in people from all
                    over the world communicating with each other. When users
                    browse and select a suitable person through matrimonial
                    websites and apps, they will be exposed to search and find
                    their love over the internet.
                  </p>
                  <div className="service-article-content">
                    <div className="row">
                      <div className="col-lg12 col-md-12">
                        <ul className="service-article-list service-article-rs">
                          <li>
                            <i className="bx bxs-check-circle"></i>Profile in
                            Depth:
                            <p>
                              Our matrimonial website has the user’s personal
                              information that is investigated in great depth.
                              It becomes even more interesting with the addition
                              of the option to upload one or more photographs.
                              Thumbnail generation and watermark embedding are
                              done automatically for each image that is
                              uploaded.
                            </p>
                          </li>
                          <li>
                            <i className="bx bxs-check-circle"></i>Different
                            Religions have Different Profiles:
                            <p>
                              ATMatrimony matrimonial website generates distinct
                              user profiles depending on their religion. For
                              example, a Muslim applicant would receive
                              different profile fields than a Christian
                              applicant who is using the same matrimonial
                              service.
                            </p>
                          </li>
                          <li>
                            <i className="bx bxs-check-circle"></i>Multiple
                            Contact Options:
                            <p>
                              We have a variety of options for communicating
                              with the user, some are more informal in nature,
                              while others are more formal. Paid members only
                              receive additional live contact information if
                              they choose to do so. This ensures that the
                              security of the user’s data is maintained.
                            </p>
                          </li>
                          <li>
                            <i className="bx bxs-check-circle"></i>Matching
                            Partners:
                            <p>
                              We have a straightforward and fast process for
                              matching partners. On the backend, a more
                              complicated algorithm procedure generates a large
                              number of emails on shared hosting. It’s the most
                              cost-effective platform on several fronts due to
                              its complexity.
                            </p>
                          </li>
                          <li>
                            <i className="bx bxs-check-circle"></i>Advanced
                            Search Options:
                            <p>
                              We’ve incorporated a variety of style search
                              alternatives, and this feature helps users to find
                              the best match.
                            </p>
                          </li>
                          <li>
                            <i className="bx bxs-check-circle"></i>
                            Administrators can Manage Easily:
                            <p>
                              We offer several levels of security, from basic to
                              advanced features, allowing you to choose the one
                              that best suits your needs precisely as they have
                              a huge list of customization options that make it
                              suitable for different applications and websites.
                              Our tools are complete and thorough; we’ve built
                              them with our customers in mind.
                            </p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 col-sm-6">
                      <div className="service-article-another-img">
                        <img
                          src="assets/images/products/atmatrimony/atmatrimony-1.jpg"
                          alt="Images"
                          className="p-img"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-sm-6">
                      <div className="service-article-another-img">
                        <img
                          src="assets/images/products/atmatrimony/atmatrimony-2.jpg"
                          alt="Images"
                          className="p-img"
                        />
                      </div>
                    </div>
                  </div>
                  <p>
                    ATMatrimony has a strong competency in matrimonial website
                    development and it is evident from our portfolio. We are
                    able to analyze the customer requirements, highlight the
                    features of a successful matrimonial website that match
                    those needs and select an appropriate technology stack for
                    building out all of these web design pieces as per those
                    parameters.
                  </p>
                </div>
                <div className="service-work-process">
                  <h2>Our Working Process</h2>
                  <p>
                    Many features of a successful matrimonial website included
                    in above are few, like Video consultation, Booking an
                    appointment, Schedule an appointment, Calendar setup, Rating
                    and review for consultation, Multilingual app. We have a
                    staff of specialists, who are well-organized with the
                    complexities of Matrimonial Website design and provide
                    unique answers. We guarantee that your websites receive the
                    greatest web traffic possible through the use of the
                    aforementioned functionalities as well as other marketing
                    techniques. Our team of skilled experts can assist you in
                    creating your own matrimonial website based on your specific
                    needs so contact us today.
                  </p>
                </div>
              </div>
            </div>
            <DetailSideBar data="product" />
          </div>
        </div>
      </div>
    </>
  );
}
