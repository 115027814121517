import React from "react";
import DetailSideBar from "../common/DetailSideBar";
import HeaderBanner from "../common/HeaderBanner";

export default function ITConsulting() {
  return (
    <>
      <HeaderBanner pageName="IT Consulting" />

      <div className="service-details-area pt-100 pb-70">
        <div className="container">
          <div className="row justify-both">
            <div className="col-lg-8">
              <div className="service-article">
                <div className="service-article-img">
                  <img src="assets/images/services/ITConsulting/it_consultancy.jpg" alt="Images" className="w-100" />
                </div>
                <div className="service-article-content">
                  <h2>IT Consulting</h2>
                  <p>IT consulting services are advisory services that help clients assess different technology strategies and, in doing
                    so, align their technology strategies with their business or process strategies. These services support customers’ IT
                    initiatives by providing strategic, architectural, operational and implementation planning. Strategic planning
                    includes advisory services that help clients assess their IT needs and formulate system implementation plans.
                    Architecture planning includes advisory services that combine strategic plans and knowledge of emerging
                    technologies to create the logical design of the system and the supporting infrastructure to meet customer requirements.
                    Operational assessment/benchmarking include services that assess the operating efficiency and capacity of a client’s
                    IT environment. Implementation planning includes services aimed at advising customers on the rollout and testing of
                    new solution deployments.</p>
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <ul className="service-article-list service-article-rs">
                        <li><i className="bx bxs-check-circle"></i>Saves Cost and Time</li>
                        <li><i className="bx bxs-check-circle"></i>Enhances Focus on the Core Business</li>
                        <li><i className="bx bxs-check-circle"></i>Boosts Productivity</li>
                      </ul>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <ul className="service-article-list">
                        <li><i className="bx bxs-check-circle"></i>Updates you with the Latest Trends</li>
                        <li><i className="bx bxs-check-circle"></i>Passionate and Dedicated Services</li>
                        <li><i className="bx bxs-check-circle"></i>Offers Variety in Experience</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="service-article-another">
                  <h2>What Benefit You Will Get</h2>
                  <p>IT consultancy is important for the growth of the business. IT consultants play an important role in business
                    management, implementation, deployment, and maintenance. Not only this but they also play an important role in developing
                    core IT strategies, doing competitor analysis, and many more things.</p>
                  <div className="row">
                    <div className="col-lg-6 col-sm-6">
                      <div className="service-article-another-img">
                        <img src="assets/images/services/ITConsulting/IT-consulting-1.jpg" alt="Images" className="p-img" />
                      </div>
                    </div>
                    <div className="col-lg-6 col-sm-6">
                      <div className="service-article-another-img">
                        <img src="assets/images/services/ITConsulting/IT-consulting-2.jpg" alt="Images" className="p-img" />
                      </div>
                    </div>
                  </div>
                  <p>IT consultants are important for organizations in the sense that they help organizations in overcoming business hurdles,
                    helping businesses in improving their performance.</p>
                </div>
                <div className="service-work-process">
                  <h2>Our Working Process</h2>
                  <p>Although finding the best IT consulting services can be tricky, once you’ve found one, there are plenty of benefits
                    to working with them. IT consultants allow you to take advantage of their broad experience and skills, helping you
                    benefit from their knowledge in a number of ways. Working with consultants is also much easier than doing it all
                    yourself—and can save you time and money in many ways. If your company is ready to take the advantage of some expert
                    guidance in an area that seems intimidating or overwhelming, then consider hiring a software consulting company in India.</p>
                </div>
              </div>
            </div>
            <DetailSideBar data='service' />
          </div>
        </div>
      </div>
    </>
  );
}
