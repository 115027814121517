import React from "react";
import { Link, NavLink } from "react-router-dom";
import BusinessReform from './../ServicesComponents/BusinessReform';

function HeaderMenu() {
  return (
    <>
      <div className="navbar-area">
        <div className="mobile-nav">
          <Link to="/" className="logo">
            <img
              src="assets/images/logos/logo-1.png"
              className="logo-one"
              alt="Logo"
            />
            <img
              src="assets/images/logos/logo-2.png"
              className="logo-two"
              alt="Logo"
            />
          </Link>
        </div>

        <div className="main-nav">
          <div className="container">
            <nav className="navbar navbar-expand-md navbar-light">
              <Link to="/" className="navbar-brand">
                <img
                  src="assets/images/logos/logo-1.png"
                  className="logo-one"
                  alt="Logo"
                />
                <img
                  src="assets/images/logos/logo-2.png"
                  className="logo-two"
                  alt="Logo"
                />
              </Link>
              <div
                className="collapse navbar-collapse mean-menu justify-content-end"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav justify-content-end mr-20 pt-45">
                  <li className="nav-item">
                    <NavLink
                      to="/"
                      className="nav-link"
                      activeclassname="active"
                    >
                      Home
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      to="/about"
                      className="nav-link"
                      activeclassname="active"
                    >
                      About
                    </NavLink>
                  </li>
                  {/* <li className="nav-item">
                    <NavLink
                      to="/products"
                      className="nav-link"
                      activeclassname="active"
                    >
                      Products
                    </NavLink>
                  </li> */}
                  {/* <li className="nav-item">
                    <NavLink
                      to="/services"
                      className="nav-link"
                      activeclassname="active"
                    >
                      Services
                    </NavLink>
                  </li> */}

                  <li className="nav-item dropdown">
                    <NavLink
                      to="/services"
                      className="nav-link dropdown-toggle"
                      id="servicesDropdown"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Services
                    </NavLink>
                    <ul className="dropdown-menu bg-light" aria-labelledby="servicesDropdown">
                      <li>
                        <NavLink to="it-cosulting" className="dropdown-item">
                          IT Consulting
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="app-development" className="dropdown-item">
                          Mobile Apps Development
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="web-development" className="dropdown-item">
                          Web Development
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="business-reform" className="dropdown-item">
                          Business Reform
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="planning" className="dropdown-item">
                          Planning
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="data-analysis" className="dropdown-item">
                          Data Analytics
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/virtual-ciso" className="dropdown-item">
                          Cyber Security
                        </NavLink>
                      </li>
                    </ul>
                  </li>

                  {/* <li className="nav-item">
                    <NavLink
                      to="/portfolio"
                      className="nav-link"
                      activeclassname="active"
                    >
                      Portfolio
                    </NavLink>
                  </li> */}
                  {/* <li className="nav-item">
                    <NavLink
                      to="/virtual-ciso"
                      className="nav-link"
                      activeclassname="active"
                    >
                      Cyber Security
                    </NavLink>
                  </li> */}
                  {/* <li className="nav-item">
                    <NavLink
                      to="/careers"
                      className="nav-link"
                      activeclassname="active"
                    >
                      Careers
                    </NavLink>
                  </li> */}
                  {/* <li className="nav-item">
                    <NavLink
                      to="/internship"
                      className="nav-link"
                      activeclassname="active"
                    >
                      Apply for internship
                    </NavLink>
                  </li> */}
                  <li className="nav-item">
                    <NavLink
                      to="/contact"
                      className="nav-link"
                      activeclassname="active"
                    >
                      Contact
                    </NavLink>
                  </li>
                </ul>
                {/* <div className="nav-side d-display">
                  <div className="nav-side-item"><div className="search-box"><i className="bx bx-search"></i></div></div>
                  <div className="nav-side-item">
                    <div className="get-btn">
                      <Link to="/contact" className="default-btn btn-bg-two border-radius-50" >
                        Get A Quote
                        <i className="bx bx-chevron-right"></i>
                      </Link>
                    </div>
                    <div className="get-btn ms-md-2">
                      <Link to="/internship" className="default-btn btn-bg-two border-radius-50">
                        Apply for internship
                        <i className="bx bx-chevron-right"></i>
                      </Link>
                    </div>
                  </div>
                </div> */}
              </div>
            </nav>
          </div>
        </div>
        <div className="side-nav-responsive">
          <div className="container-max">
            {/* <div className="dot-menu">
              <div className="circle-inner">
                <div className="in-circle circle-one"></div>
                <div className="in-circle circle-two"></div>
                <div className="in-circle circle-three"></div>
              </div>
            </div> */}
            <div className="container">
              <div className="side-nav-inner">
                <div className="side-nav justify-content-center align-items-center">
                  <div className="side-nav-item nav-side">
                    {/* <div className="search-box"><i className="bx bx-search"></i></div> */}
                    <div className="get-btn">
                      <Link
                        to="/contact"
                        className="default-btn btn-bg-two border-radius-50"
                      >
                        Get A Quote <i className="bx bx-chevron-right"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="search-overlay">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="search-layer"></div>
            <div className="search-layer"></div>
            <div className="search-layer"></div>
            <div className="search-close">
              <span className="search-close-line"></span>
              <span className="search-close-line"></span>
            </div>
            <div className="search-form">
              <form>
                <input type="text" className="input-search" placeholder="Search here..." />
                <button type="submit"><i className="bx bx-search"></i></button>
              </form>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
}

export default HeaderMenu;
