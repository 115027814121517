export const CONTACT_NUMBER = `+91 83 90 42 6222`;
export const CONTACT_NUMBER2 = `+918390426222`;
export const CONTACT_EMAIL = `info@atjoin.in`;
export const COMPANY_NAME = `ATJOIN PVT. LTD.`;
export const COMPANY_ADDRESS1 = `Building No. 06, Mundhe Apt., Mathura Shinde Nagar, Paregaon Road, Yeola, Nashik - 423401.`;
export const COMPANY_ADDRESS2 = `5th Floor, Luv Kush Apartment, DP Road, Next to Season Business Centre, Sanewadi, Aundh, Pune - 411007.`;
export const COMPANY_WEBSITE = `https://atjoin.in`;
export const COMPANY_EMAIL = `info@atjoin.in`;
export const COMPANY_PHONE = `+91 83 90 42 6222`;

export const CONTACT_PUNE1 = `+91 84 21 92 4019`;
export const CONTACT_PUNE11 = `+918421924019`;
export const CONTACT_PUNE2 = `+91 83 90 42 6222`;
export const CONTACT_PUNE21 = `+918390426222`;

export const CONTACT_YEOLA1 = `(02559) 266784`;
export const CONTACT_YEOLA11 = `02559266784`;
export const CONTACT_YEOLA2 = `+91 94 22 33 3877`;
export const CONTACT_YEOLA21 = `+919422333877`;

export const YEAR_OF_FOUNDATION = 2016;
export const YEAR_OF_EXPERIENCE = new Date().getFullYear() - YEAR_OF_FOUNDATION;

export const VISION = `To provide way to make worlds every organization digital.`;
export const MISSION = `Connecting organizations through digital platform.`;
export const VALUES = `Develop with uncompromising honesty and integrity in everything we do.`;
