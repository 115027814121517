import axios from "axios";

// const Base_Url = "http://localhost:5000/atds-api/";
const Base_Url = "https://atjoin-api.atopd.in/atds-api/";
const phonepePleskUrl = "https://sndlmsapi.atopd.in/api";
class Service {
  payNow(data, xVerify) {
    var newData = {
      request: data,
      xVerify: xVerify,
    };
    return axios.post(phonepePleskUrl + "/Employee/phonepe", newData);
  }

  checkPaymentStatus(xVerify, merchantId, merchantTransactionId) {
    var newData = {
      xVerify: xVerify,
      merchantId: merchantId,
      merchantTransactionId: merchantTransactionId,
    };
    return axios.post(
      phonepePleskUrl + "/Employee/check-payment-status",
      newData
    );
  }
  //Arnav services
  getAllVacancy() {
    return axios.get(Base_Url + "vacancy/getAll-vacancy");
  }

  getVacancy(data) {
    return axios.post(Base_Url + "vacancy/get-vacancy", data);
  }

  getAllCategory() {
    return axios.get(Base_Url + "category/get-category");
  }

  getAllJobtype() {
    return axios.get(Base_Url + "jobtype/get-jobtype");
  }

  getAllLocation() {
    return axios.get(Base_Url + "location/get-location");
  }

  applyJob(data) {
    return axios.post(Base_Url + "apply/applyjob", data);
  }

  //Debug Services-

  careerFreshers(data) {
    return axios.post(Base_Url + "careerFreshers/career-fresher", data);
  }

  careerExperiance(data) {
    return axios.post(Base_Url + "careerExperiance/careerExperiance", data);
  }

  saveEnquiry(data) {
    return axios.post(Base_Url + "careerFreshers/save-enquiry", data);
  }

  // Display Careers

  getAllFreshers() {
    return axios.get(Base_Url + "careerFreshers/get-career-freshers");
  }

  getAllExperienced() {
    return axios.get(Base_Url + "careerExperiance/get-career-experienced");
  }

  // internship api's

  getAllInternship() {
    return axios.get(Base_Url + "internship/getAll");
  }

  getInternshipPackages() {
    return axios.get(Base_Url + "internship/get-all-packages");
  }

  getTechnologies(id) {
    return axios.get(Base_Url + "internship/get-technologies/" + id);
  }

  getInternshipMode(id) {
    return axios.get(Base_Url + "internship/get-mode-of-internship/" + id);
  }

  getPaymentType(id) {
    return axios.get(Base_Url + "internship/get-payment-type/" + id);
  }

  applyForInternship(data) {
    return axios.post(Base_Url + "internship/apply-for-internship", data);
  }

  changePaymentStatus(data) {
    return axios.post(Base_Url + "internship/change-payment-status", data);
  }

  deleteInternshipRecord(id) {
    return axios.delete(Base_Url + "internship/delete-record/" + id);
  }
}
export default new Service();
