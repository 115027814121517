import React from "react";
import DetailSideBar from "../common/DetailSideBar";
import HeaderBanner from "../common/HeaderBanner";

export default function AppDevelopment() {
  return (
    <>
      <HeaderBanner pageName="App Development" />
      <div className="service-details-area pt-100 pb-70">
        <div className="container">
          <div className="row justify-both">
            <div className="col-lg-8">
              <div className="service-article">
                <div className="service-article-img">
                  <img src="assets/images/services/AppDevelopment/banner.jpg" alt="Images" className="w-100" />
                </div>
                <div className="service-article-content">
                  <h2>App Development</h2>
                  <p>Planning remains an integral part of this phase in the mobile app development process. Before actual
                    development/programming efforts start, you will have to: In addition to the points above, if the scope of your mobile
                    app development includes technologies such as Augmented Reality (AR) and Artificial Intelligence (AI), you will need
                    to verify that your app development team has the capabilities to design and implement them. Taking such a
                    technological approach will enable your app to provide atypical, rich user experiences. Thus, greatly
                    differentiating your app from those existing in the market and your company from its competitors.</p>
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <ul className="service-article-list service-article-rs">
                        <li><i className="bx bxs-check-circle"></i>Custom iOS and Android apps development</li>
                        <li><i className="bx bxs-check-circle"></i>Native and cross-platform solutions</li>
                        <li><i className="bx bxs-check-circle"></i>Second platform app development</li>
                      </ul>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <ul className="service-article-list">
                        <li><i className="bx bxs-check-circle"></i>UI/UX design</li>
                        <li><i className="bx bxs-check-circle"></i>Consulting and prototyping</li>
                        <li><i className="bx bxs-check-circle"></i>Good Services</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="service-article-another">
                  <h2>What Benefit You Will Get</h2>
                  <p>We offer a full cycle of application design, integration and management services. Whether it is a consumer oriented app
                    or a transformation enterprise-className solution, the company leads the entire mobile app development process from
                    ideation and concept to delivery, and to ongoing ongoing support.</p>
                  <div className="row">
                    <div className="col-lg-6 col-sm-6">
                      <div className="service-article-another-img">
                        <img src="assets/images/services/AppDevelopment/process.png" alt="Images" className="p-img" />
                      </div>
                    </div>
                    <div className="col-lg-6 col-sm-6">
                      <div className="service-article-another-img">
                        <img src="assets/images/services/AppDevelopment/process2.png" alt="Images" className="p-img" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="service-work-process">
                  <h2>Our Working Process</h2>
                  <p>To start the process, we just need to know what you’re hoping to achieve with the project. Ideally, your brief
                    should have isolated what the problem is, what they think mobile could do to help, and worked out a rough idea of the
                    objective – but the bare minimum is simply knowing what the issue you want to solve with mobile. This is often something
                    you could write down in a single paragraph. It could be as simple as ‘we lose loads of money from using a paper-based
                    process for this thing, we want to digitize it’. We can help fill the holes, but we need to know where they are in the
                    first place. Once we have that single problem, we can start working to solve it.</p>
                </div>
              </div>
            </div>
            <DetailSideBar data='service' />
          </div>
        </div>
      </div>
    </>
  );
}
