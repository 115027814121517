import React from "react";
import HeaderBanner from "../common/HeaderBanner";

const VCISO = () => {
  const benifits = [
    {
      id: 1,
      name: "Cost-Effective",
      description:
        "Hiring a full-time CISO can be expensive, particularly for businesses that may not have the budget for such a specialized role. A Virtual CISO gives you access to the same level of expertise but at a fraction of the cost. Instead of paying for a permanent position, you only pay for the services you need, when you need them.",
    },
    {
      id: 2,
      name: "Expert Guidance",
      description:
        "At ATJOIN, our vCISO service is led by cybersecurity experts with years of experience in various industries. This means your business receives access to cutting-edge cybersecurity leadership that stays up to date with the latest threats, trends, and technologies. Our team provides you with strategic advice on managing cyber risks effectively.",
    },
    {
      id: 3,
      name: "Tailored Security Strategy",
      description:
        "Every organization has different needs, depending on factors such as its size, industry, and existing cybersecurity measures. ATJOIN’s vCISO service designs a security strategy specifically for your organization, ensuring that your systems and data are protected in the most efficient way possible.",
    },
    {
      id: 4,
      name: "Compliance Assurance",
      description:
        "Keeping up with industry regulations like GDPR, HIPAA, and ISO standards can be overwhelming. Our vCISO helps you navigate these complex requirements and ensures your organization is compliant with all necessary regulations, reducing the risk of fines or legal issues.",
    },
    {
      id: 5,
      name: "Risk Mitigation",
      description:
        "Cybersecurity isn’t just about reacting to threats—it’s about being proactive. Our vCISO service focuses on identifying potential risks before they become critical issues. We implement risk mitigation strategies to safeguard your business from the most likely and impactful threats.",
    },
  ];

  const services = [
    {
      id: 1,
      name: "Security Program Development",
      description:
        "Our vCISO will help develop a comprehensive cybersecurity program for your organization. This includes setting policies, implementing best practices, and aligning your security initiatives with your business objectives. We ensure your security framework is robust enough to handle current and future threats.",
    },
    {
      id: 2,
      name: "Incident Response",
      description:
        "In the event of a security breach, having a plan in place is critical. We create and implement incident response plans to ensure that your team can quickly and effectively respond to a cyber incident. This minimizes downtime, limits damage, and gets your business back on track as soon as possible.",
    },
    {
      id: 3,
      name: "Risk Management",
      description:
        "Our vCISO service includes a thorough risk assessment to identify vulnerabilities in your systems and processes. Once we’ve identified the risks, we prioritize them based on their potential impact and likelihood. We then create and implement strategies to mitigate these risks and improve your overall security posture.",
    },
    {
      id: 4,
      name: "Compliance Management",
      description:
        "Ensuring compliance with data protection and security standards is vital for any business, particularly those in regulated industries. Our vCISO will assist in managing compliance efforts, helping you stay up to date with industry regulations and avoid costly penalties for non-compliance.",
    },
    {
      id: 5,
      name: "Security Awareness Training",
      description:
        "Your employees are your first line of defense against cyber threats. We provide comprehensive security awareness training for your team, ensuring they are educated on the latest threats and know how to identify and respond to potential attacks, such as phishing or malware.",
    },
  ];

  const keyDifferences = [
    {
      id: 1,
      name: "Proven Expertise",
      description:
        "With over six years of experience in IT solutions and digital security, our vCISO service is led by cybersecurity experts with hands-on experience in multiple industries. Our team stays updated with the latest technologies, regulations, and attack vectors to offer cutting-edge protection.",
    },
    {
      id: 2,
      name: "Customized Approach",
      description:
        "We understand that no two businesses are the same. That’s why we take the time to assess your organization’s specific risks and challenges before developing a tailored cybersecurity strategy. Our solutions are built to fit your organization’s size, industry, and unique security needs.",
    },
    {
      id: 3,
      name: "Ongoing Support",
      description:
        "Cyber threats don’t take a break, and neither do we. Our vCISO service provides ongoing monitoring, support, and updates to ensure your business stays secure even as new threats emerge. We’re here to help you every step of the way, offering continuous protection and adjustments as needed.",
    },
    {
      id: 4,
      name: "Scalable Solutions",
      description:
        "As your business grows, your cybersecurity needs will evolve. Our vCISO services are designed to scale with your business, providing the flexibility to increase the level of security as your organization expands and encounters new risks.",
    },
    {
      id: 5,
      name: "Transparency & Communication",
      description:
        "We believe in clear, open communication. ATJOIN provides regular reports and updates, so you’ll always know where your organization stands in terms of security. We ensure your leadership team is fully informed, giving you the confidence to make informed decisions about your cybersecurity strategy.",
    },
  ];

  const audit = [
    {
      id: 1,
      name: "Technical Audit and Compliance Assurance:",
      description:
        "Our comprehensive technical audit ensures the integrity and reliability of your systems, identifying potential vulnerabilities and areas for improvement. Simultaneously, our compliance assurance services guarantee adherence to regulatory requirements, industry standards, and organizational policies.",
    },
  ];

  const keyDeliverables = [
    {
      id: 1,
      name: "Key Deliverables:",
      description: (
        <ul>
          <li>Identification and mitigation of technical risks.</li>
          <li>Enhanced system security and performance.</li>
          <li>
            Compliance with relevant laws, regulations, and standards (e.g.,
            GDPR, HIPAA, PCI-DSS).
          </li>
          <li>Improved data integrity and governance.</li>
          <li>Customized recommendations for process optimization.</li>
          <li>Regular audit and compliance reporting.</li>
        </ul>
      ),
    },
  ];

  const containerStyle = {
    display: 'flex',
    justifyContent: 'center', // Horizontal center
    alignItems: 'center',     // Vertical center
    minHeight: '200px',       // Optional: For demonstration
  };

  const imageStyle = {
    maxWidth: '100%',  // Makes the image responsive
    height: 'auto'     // Preserves aspect ratio
  };

  return (
    <>
      <HeaderBanner pageName="Virtual CISO(Cyber Security)" />
      <div className="container">
        <div className="my-5 justify-both">
          <div className="row">
            <div className="col-md-6 col-12 order-1 order-md-0">
              <p>
                The increasing reliance on digital tools and platforms means
                organizations face more sophisticated and frequent cybersecurity
                threats. However, many companies—especially small to
                medium-sized businesses—do not have the resources to hire a
                full-time Chief Information Security Officer (CISO) to handle
                these challenges. That’s where Virtual CISO (vCISO) as a Service
                comes in. This service offers businesses access to high-level
                security expertise and leadership without the need for a
                full-time hire. ATJOIN Pvt. Ltd. helps businesses protect
                themselves from emerging cyber threats, manage risks, and
                maintain compliance, all while controlling costs.
              </p>
            </div>
            <div className="col-md-6 col-12 text-center order-0 order-md-1">
              <img
                src="/assets/images/ciso/1.png"
                alt="Virtual CISO (vCISO) Benefits"
                className="img-fluid w-75"
              />
            </div>
          </div>
          <h3 className="subscp-title txt22u mt-5">
            Virtual CISO (vCISO) Benefits:
          </h3>
          <div className="row">
            <div className="col-md-6 col-12r" style={containerStyle}>
              <img
                src="/assets/images/ciso/2.png"
                alt="Virtual CISO (vCISO) Benefits"
                className="img-fluid"
                style={imageStyle}
              />
            </div>
            <div className="col-12 col-md-6">
              <ol>
                {benifits.map((benifit) => (
                  <li key={benifit.id}>
                    <b>{benifit.name}:</b>
                    <p>{benifit.description}</p>
                  </li>
                ))}
              </ol>
            </div>
          </div>

          <h3 className="subscp-title txt22u">Our Virtual CISO as a Service</h3>
          <p>
            Our Virtual CISO offering at ATJOIN Pvt. Ltd. provides a complete
            package of security services, tailored to your company’s specific
            needs. Whether you’re looking for a short-term engagement or an
            ongoing partnership, we provide flexible solutions that address your
            cybersecurity concerns. Here’s what you can expect:
          </p>
          <div className="row">
            <div className="col-12 col-md-6 order-1 order-md-0">
              <ol>
                {services.map((service) => (
                  <li key={service.id}>
                    <b>{service.name}:</b>
                    <p>{service.description}</p>
                  </li>
                ))}
              </ol>
            </div>
            <div className="col-md-6 col-12 order-0 order-md-1" style={containerStyle}>
              <img
                src="/assets/images/ciso/3.png"
                alt="Virtual CISO (vCISO) Benefits"
                className="img-fluid"
                style={imageStyle}
              />
            </div>
          </div>

          <h3 className="subscp-title txt22u">Key Differentiators</h3>
          <p>
            What sets ATJOIN’s Virtual CISO service apart from other providers
            is our deep understanding of the cybersecurity landscape and our
            commitment to delivering personalized solutions that meet your
            organization’s unique needs. Here are the key differentiators:
          </p>
          <div className="row">
            <div className="col-md-6 col-12" style={containerStyle}>
              <img
                src="/assets/images/ciso/4.png"
                alt="Virtual CISO (vCISO) Benefits"
                className="img-fluid"
                style={imageStyle}
              />
            </div>
            <div className="col-12 col-md-6">
              <ol>
                {keyDifferences.map((keyDifference) => (
                  <li key={keyDifference.id}>
                    <b>{keyDifference.name}:</b>
                    <p>{keyDifference.description}</p>
                  </li>
                ))}
              </ol>
            </div>
          </div>

          <h3 className="subscp-title txt22u">
            Technical Audit and Compliance Assurance:
          </h3>
          <p>{audit[0].description}</p>

          <h3 className="subscp-title txt22u">Key Deliverables:</h3>
          <p>{keyDeliverables[0].description}</p>
          <p>
            By integrating technical audit and compliance assurance, we provide
            a holistic approach to risk management, empowering organizations to
            achieve operational excellence, maintain regulatory compliance, and
            safeguard their reputation.
          </p>
        </div>
      </div>
    </>
  );
};

export default VCISO;
