import React from "react";
import DetailSideBar from "../common/DetailSideBar";
import HeaderBanner from "../common/HeaderBanner";

export default function DataAnalyst() {
  return (
    <>
      <HeaderBanner pageName="Data Analysis" />

      <div className="service-details-area pt-100 pb-70">
        <div className="container">
          <div className="row justify-both">
            <div className="col-lg-8">
              <div className="service-article">
                <div className="service-article-img">
                  <img src="assets/images/services/dataAnalyst/banner.avif" alt="Images" className="w-100" />
                </div>
                <div className="service-article-content">
                  <h2>Data Analyst</h2>
                  <p>Although many groups, organizations, and experts have different ways to approach data analysis, most of them can
                    be distilled into a one-size-fits-all definition. Data analysis is the process of cleaning, changing, and
                    processing raw data, and extracting actionable, relevant information that helps businesses make informed decisions.
                    The procedure helps reduce the risks inherent in decision-making by providing useful insights and statistics,
                    often presented in charts, images, tables, and graphs.</p>
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <ul className="service-article-list service-article-rs">
                        <li><i className="bx bxs-check-circle"></i>Better Decision-Making</li>
                        <li><i className="bx bxs-check-circle"></i>More Accessible Analytics</li>
                        <li><i className="bx bxs-check-circle"></i>Automation</li>
                      </ul>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <ul className="service-article-list">
                        <li><i className="bx bxs-check-circle"></i>Predictive Modeling</li>
                        <li><i className="bx bxs-check-circle"></i>Personalization</li>
                        <li><i className="bx bxs-check-circle"></i>Retention and Loyalty</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="service-article-another">
                  <h2>What Benefit You Will Get</h2>
                  <p>Better Customer Targeting: You don’t want to waste your business’s precious time, resources, and money putting
                    together advertising campaigns targeted at demographic groups that have little to no interest in the goods and
                    services you offer. Data analysis helps you see where you should be focusing your advertising efforts.</p>
                  <div className="row">
                    <div className="col-lg-6 col-sm-6">
                      <div className="service-article-another-img">
                        <img src="assets/images/services/dataAnalyst/img1.avif" alt="Images" className="p-img" />
                      </div>
                    </div>
                    <div className="col-lg-6 col-sm-6">
                      <div className="service-article-another-img">
                        <img src="assets/images/services/dataAnalyst/img2.jpg" alt="Images" className="p-img" />
                      </div>
                    </div>
                  </div>
                  <p>Reduce Operational Costs: Data analysis shows you which areas in your business need more resources and money, and
                    which areas are not producing and thus should be scaled back or eliminated outright.</p>
                </div>
                <div className="service-work-process">
                  <h2>Our Working Process</h2>
                  <p>Designing and maintaining data systems and databases; this includes fixing coding errors and other data-related
                    problems. Mining data from primary and secondary sources, then reorganizing said data in a format that can be easily
                    read by either human or machine. Using statistical tools to interpret data sets, paying particular attention to trends
                    and patterns that could be valuable for diagnostic and predictive analytics efforts. Demonstrating the significance
                    of their work in the context of local, national, and global trends that impact both their organization and industry.</p>
                </div>
              </div>
            </div>
            <DetailSideBar data='service' />
          </div>
        </div>
      </div>
    </>
  );
}
