import React, { useEffect, useState } from "react";
import Service from "../Services/Service";
import ExportToExcel from "../Services/ExportToExcel";
import moment from "moment";
import { Link } from "react-router-dom";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";

export default function ViewAllInternshipStudents() {
  const [internship, setInternship] = useState();

  useEffect(() => {
    loadAllInternshipStudents();
  }, []);

  const loadAllInternshipStudents = () => {
    Service.getAllInternship().then((res) => {
      if (res && res.data) {
        setInternship(res.data);
        console.log(res.data);
      }
    });
  };

  const downloadExcel = () => {
    var data = internship;
    for (let i = 0; i < data.length; i++) {
      data[i].Resume = "https://atjoin-api.atopd.in/" + data[i].Resume;
    }
    ExportToExcel.exportToExcel(data, "Internship Excel");
  };

  const changeStatus = (item) => {
    if (window.confirm(`You want to change payment status of ${item.Name}`)) {
      var data = {
        PaymentStatus: item.PaymentStatus,
        IRId: item.IRId,
      };
      Service.changePaymentStatus(data).then((res) => {
        if (res.data.success) {
          alert(`Success : ${item.Name} Payment status has been change..!`);
          loadAllInternshipStudents();
        }
      });
    }
  };

  const deleteRecord = (item) => {
    if (window.confirm(`You want to delete record of ${item.Name}`)) {
      Service.deleteInternshipRecord(item.IRId).then((res) => {
        if (res.data.success) {
          alert(`Success : ${item.Name} record deleted..!`);
          loadAllInternshipStudents();
        }
      });
    }
  };

  const logout = () => {
    localStorage.removeItem("isLoggedIn");
    document.location.replace("/");
  };

  return (
    <div className="hscroll">
      <div className="col-12 text-end career-btn-mt">
        <Link to="/view-all-freshers">
          <button type="submit" className="btn btn-primary mr-20 career-btn-mx">
            View Freshers
          </button>
        </Link>
        <Link to="/view-all-experienced">
          <button type="submit" className="btn btn-primary mr-20 career-btn-mx">
            View Experienced
          </button>
        </Link>
        <button
          type="submit"
          className="btn btn-success mr-20 career-btn-mx"
          onClick={() => downloadExcel()}
        >
          Download Excel
        </button>
        <button
          type="submit"
          className="btn btn-primary mr-20 career-btn-mx"
          onClick={() => logout()}
        >
          Logout
        </button>
      </div>
      <div className="col-12 text-center mt-3">
        <h2>Internship Candidates</h2>
      </div>
      {/* <div className="row my-3">
        <div className="col-4">Show All</div>
        <div className="col-4">Payment Status Completed</div>

        <div className="col-4">Payment Status Not Completed</div>
      </div> */}
      <div>
        <Table>
          <Thead>
            <Tr className="text-center">
              <Th>Sr. No.</Th>
              <Th>Name</Th>
              <Th>Contact</Th>
              <Th>Email</Th>
              <Th>Internship Package</Th>
              <Th>Technology/Language</Th>
              <Th>Internship Mode</Th>
              <Th>Payment Type</Th>
              <Th>Payment Link</Th>
              <Th>Resume</Th>
              <Th>Payment Status</Th>
              <Th>Reg. Date</Th>
              <Th>Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            {internship &&
              internship.map((item, i) => (
                <Tr key={i}>
                  <Td>{i + 1}</Td>
                  <Td>{item.Name}</Td>
                  <Td>{item.Contact}</Td>
                  <Td>{item.Email}</Td>
                  <Td>{item.Package}</Td>
                  <Td>{item.Technology}</Td>
                  <Td>{item.Mode}</Td>
                  <Td>{item.Type}</Td>
                  <Td>
                    <a target={"_blank"} href={item.PaymentLink} rel='noreferrer'>
                      Payment Link
                    </a>
                  </Td>
                  <Td>
                    <a target={"_blank"} href={"https://atjoin-api.atopd.in/" + item.Resume} rel='noreferrer'>
                      View Resume
                    </a>
                  </Td>

                  <Td
                    className={
                      item.PaymentStatus === "Pending"
                        ? "bg-danger text-white"
                        : "bg-success text-white"
                    }
                  >
                    {item.PaymentStatus}
                  </Td>
                  <Td>{moment(item.Date).format("DD-MM-YYYY")}</Td>
                  <Td>
                    <button
                      onClick={() => changeStatus(item)}
                      type="button"
                      className="btn py-1 m-2 btn-primary"
                    >
                      {item.PaymentStatus === "Pending"
                        ? "Fees Paid"
                        : "Fees Not Paid"}
                    </button>

                    <button
                      onClick={() => deleteRecord(item)}
                      type="button"
                      className="btn py-1 m-2 btn-danger"
                    >
                      Delete
                    </button>
                  </Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
      </div>
    </div>
  );
}
