import React from "react";
import DetailSideBar from "../common/DetailSideBar";
import HeaderBanner from "../common/HeaderBanner";

export default function Atbeloved() {
  return (
    <>
      <HeaderBanner pageName="Atbeloved" />

      <div className="service-details-area pt-100 pb-70">
        <div className="container">
          <div className="row justify-both">
            <div className="col-lg-8">
              <div className="service-article">
                <div className="service-article-img">
                  <a href="https://atjoin.in/atbeloved"><img src="assets/images/case-study/atbeloved.png" alt="Images" className="w-100" /></a>
                </div>
                <div className="service-article-content">
                  <h2>ATBELOVED</h2>
                  <p>ATBELOVED platforms allow users to have conversations, share information and create web content. There are many forms of social media, including blogs, micro-blogs, wikis, social networking sites, photo-sharing sites, instant messaging, video-sharing sites, podcasts, widgets, virtual worlds, and more.</p>
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <ul className="service-article-list service-article-rs">
                        <li><i className="bx bxs-check-circle"></i>Memories of Loved</li>
                        <li><i className="bx bxs-check-circle"></i>Share Posts</li>
                      </ul>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <ul className="service-article-list">
                        <li><i className="bx bxs-check-circle"></i>Updates you with the Latest Trends</li>
                        <li><i className="bx bxs-check-circle"></i>Offers Variety in Experience</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="service-article-another">
                  <h2>What Benefit You Will Get</h2>
                  <p>Billions of people around the world use social media to share information and make connections. On a personal level, social media allows you to communicate with friends and family, learn new things, develop your interests, and be entertained. On a professional level, you can use social media to broaden your knowledge in a particular field and build your professional network by connecting with other professionals in your industry. At the company level, social media allows you to have a conversation with your audience, gain customer feedback, and elevate your brand.</p>
                  <div className="row">
                    <div className="col-lg-6 col-sm-6">
                      <div className="service-article-another-img">
                        <img src="assets/images/products/atbeloved/atbeloved-1.png" alt="Images" className="p-img" />
                      </div>
                    </div>
                    <div className="col-lg-6 col-sm-6">
                      <div className="service-article-another-img">
                        <img src="assets/images/products/atbeloved/atbeloved-2.png" alt="Images" className="p-img" />
                      </div>
                    </div>
                  </div>
                  <p>In today's society, the use of social media has become a necessary daily activity. ATBELOVED is typically used for social interaction and access to news and information, and decision making. It is a valuable communication tool with others locally and worldwide, as well as to share, create, and spread information.</p>
                </div>
                <div className="service-work-process">
                  <h2>Our Working Process</h2>
                  <p>ATBELOVED helps you maintain relationships by making it easy to reach family and friends who live far; it closes the gap created by distance. You may have not seen someone for a few years but newsfeed updates keep you in the loop of what is happening in their lives.</p>
                </div>
              </div>
            </div>
            <DetailSideBar data='product' />
          </div>
        </div>
      </div>
    </>
  );
}
