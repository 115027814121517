import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Service from "../Services/Service";

export default function CareersFreshers() {
  const [loading, setLoading] = useState(false);

  //showing alert-
  const [isShow, setIsShow] = useState(false);
  const [alertClass, setAlertClass] = useState();
  const [msg, setMsg] = useState();
  const [resumeValidation, setResumeValidation] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  //savedata
  const loadFresherData = (data) => {
    if (data.Resume[0].type === "application/pdf") {
      let fd = new FormData();
      fd.append("Technology", data.Technology);
      fd.append("Duration", "6 Month");
      fd.append("PrefferedMode", data.PrefferedMode);
      fd.append("Name", data.Name);
      fd.append("Address", data.Address);
      fd.append("Contact", data.Contact);
      fd.append("Email", data.Email);
      fd.append("Qualification", data.Qualification);
      fd.append("Percentage", data.Percentage);
      fd.append("CollegeName", data.CollegeName);
      fd.append("UniversityName", data.UniversityName);
      fd.append("Resume", data.Resume[0]);
      fd.append("RegisterBy", "ATJOIN");
      setLoading(true);
      Service.careerFreshers(fd).then((res) => {
        if (res.data.Success === "Data Saved sucessfully.") {
          // alert
          // setIsShow(true);
          // setAlertClass("alert alert-success");
          // setMsg(res.data.Success);
          // setMsg('Success. Please wait for our email or call back. Thank you.');
          setLoading(false);
          alert(
            "Success. Thank you for showing interest in ATJOIN PVT. LTD. \n\nPlease wait for our email or call back. "
          );
          reset();
        } else if (res.data.warning === "Contact already exists...!") {
          setLoading(false);
          setIsShow(true);
          setAlertClass("alert alert-danger");
          setMsg(res.data.warning);
        }
        setTimeout(() => {
          setIsShow(false);
        }, 5000);
      });
    } else {
      setResumeValidation(true);
      setAlertClass("alert alert-danger");
      setMsg(
        `Invalid file format. Please upload a valid file format. e.g. pdf, doc, docx`
      );
      setLoading(false);
      setTimeout(() => {
        setIsShow(false);
        setResumeValidation(false);
      }, 3000);
    }
  };

  return (
    <>
      <ul>
        <form onSubmit={handleSubmit(loadFresherData)}>
          <li>
            <div className="content">
              <div className="career-number">
                <strong>1</strong>
              </div>
              <h3 className="pt-2 text-dark">I'm applying for</h3>
            </div>

            <div className="contact-form">
              {/* <div className="row">
                <div className="col-sm-6 col-md-6 col-lg-6">
                  <div className="collapse show" id="collapseFilters">
                    <div className="filter_type">
                      <ul>
                        <li>
                          <label className="text-dark">
                            <input type="radio" name="Post" value="Internship" className="rcheck mt-3" checked
                              {...register("Post", { required: "Please select post." })} />{" "}Internship
                          </label>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-6">
                  <div className="collapse show" id="collapseFilters">
                    <div className="filter_type">
                      <ul>
                        <li>
                          <label className="text-dark">
                            <input type="radio" name="Post" value=" On Job Traning" className="rcheck mt-3"
                              {...register("Post", { required: "Please select post." })} />{" "}On Job Traning (Become industry ready)
                          </label>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="row">
                {/* <div className="col-sm-6">
                  <span>
                    <select className="nice-select" type="text" id=""
                      {...register("Duration", { required: "Please select duration." })}>
                      <option value="">Duration (In Days) </option>
                      
                      <option value="90 Days">90 Days</option>
                      <option value="180 Days">180 Days</option>
                    </select>
                    <label className="input_label"></label>
                    {errors.Duration && (<span className="text-danger"><small>{errors.Duration.message}</small></span>)}
                  </span>
                </div> */}
                <div className="col-sm-6">
                  <span>
                    <select
                      className="nice-select"
                      type="text"
                      id=""
                      {...register("Technology", {
                        required: "Please select technology.",
                      })}
                    >
                      <option value="">Select Technology</option>
                      <option value="React Native">React Native</option>
                      <option value="Dot Net">Dot Net Full Stack</option>
                      <option value="MERN">MERN Stack</option>
                      <option value="MEAN">MEAN Stack</option>
                      <option value="PHP">PHP Full Stack</option>
                      <option value="UI/UX">UI/UX</option>
                      <option value="JAVA">JAVA Full Stack</option>
                      <option value="FLUTTER">FLUTTER</option>
                      <option value="DATA ANALYST">DATA ANALYST</option>
                    </select>
                    <label className="input_label">
                      <span className="input__label-content"></span>
                    </label>
                    {errors.Post && (
                      <span className="text-danger">
                        <small>{errors.technology.message}</small>
                      </span>
                    )}
                  </span>
                </div>
                <div className="col-sm-6 dw-mt">
                  <span>
                    <select
                      className="nice-select"
                      type="text"
                      id=""
                      {...register("PrefferedMode", {
                        required: "Please select preffered mode.",
                      })}
                    >
                      <option value="">Preffered Mode</option>
                      <option value="From Office">From Office</option>
                      <option value="From Home">From Home</option>
                    </select>
                    <label className="input_label"></label>
                    {errors.PrefferedMode && (
                      <span className="text-danger">
                        <small>{errors.PrefferedMode.message}</small>
                      </span>
                    )}
                  </span>
                </div>
              </div>
            </div>
          </li>
          <hr />
          <li>
            <div className="content">
              <div className="career-number">
                <strong>2</strong>
              </div>
              <h3 className="pt-2 text-dark">Personal Details</h3>
            </div>

            <div className="contact-form">
              <div className="row">
                <div className="col-lg-6">
                  <div className="form-group">
                    <label className="text-dark">Candidate Name *</label>
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      {...register("Name", {
                        required: "Please enter Name.",
                        pattern: {
                          value: /^[a-z A-Z]+$/,
                          message: "Please enter valid name.",
                        },
                      })}
                    />
                    {errors.Name && (
                      <span className="text-danger">
                        <small>{errors.Name.message}</small>
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <label className="text-dark">Address *</label>
                    <input
                      type="text"
                      name="address"
                      className="form-control"
                      {...register("Address", {
                        required: "Please enter address.",
                      })}
                    />
                    {errors.Address && (
                      <span className="text-danger">
                        <small>{errors.Address.message}</small>
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <label className="text-dark">Contact Number *</label>
                    <input
                      type="number"
                      name="phone_number"
                      className="form-control"
                      maxLength={10}
                      onInput={(e) => {
                        if (e.target.value.length > e.target.maxLength)
                          e.target.value = e.target.value.slice(
                            0,
                            e.target.maxLength
                          );
                      }}
                      {...register("Contact", {
                        required: "Please enter contact number.",
                        pattern: {
                          value: /^[0][1-9]\d{9}$|^[1-9]\d{9}$/,
                          message: "Please enter valid contact number.",
                        },
                      })}
                    />
                    {errors.Contact && (
                      <span className="text-danger">
                        <small>{errors.Contact.message}</small>
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <label className="text-dark">Email ID *</label>
                    <input
                      type="text"
                      name="msg_subject"
                      className="form-control"
                      {...register("Email", {
                        required: "Please enter email ID.",
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: "Please enter valid email ID.",
                        },
                      })}
                    />
                    {errors.Email && (
                      <span className="text-danger">
                        <small>{errors.Email.message}</small>
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </li>
          <hr />
          <li>
            <div className="content">
              <div className="career-number">
                <strong>3</strong>
              </div>
              <h3 className="pt-2 text-dark">Education Details</h3>
            </div>

            <div className="contact-form">
              <div className="row">
                <div className="col-lg-6">
                  <div className="form-group">
                    <label className="text-dark">Qualification *</label>
                    <input
                      type="text"
                      name="qualification"
                      className="form-control"
                      {...register("Qualification", {
                        required: "Please enter qualification.",
                      })}
                    />
                    {errors.Qualification && (
                      <span className="text-danger">
                        <small>{errors.Qualification.message}</small>
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <label className="text-dark">Percentage *</label>
                    <input
                      type="text"
                      name="percentage"
                      className="form-control"
                      {...register("Percentage", {
                        required: "Please enter percentage.",
                        pattern: {
                          value: /^([0-9]{1,})?(\.)?([0-9]{1,})?$/,
                          message: "Please enter valid percentage.",
                        },
                      })}
                    />
                    {errors.Percentage && (
                      <span className="text-danger">
                        <small>{errors.Percentage.message}</small>
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <label className="text-dark">College Name *</label>
                    <input
                      type="text"
                      name="phone_number"
                      className="form-control"
                      {...register("CollegeName", {
                        required: "Please enter college name.",
                      })}
                    />
                    {errors.CollegeName && (
                      <span className="text-danger">
                        <small>{errors.CollegeName.message}</small>
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <label className="text-dark">University Name *</label>
                    <input
                      type="text"
                      name="msg_subject"
                      className="form-control"
                      {...register("UniversityName", {
                        required: "Please enter university name.",
                      })}
                    />
                    {errors.UniversityName && (
                      <span className="text-danger">
                        <small>{errors.UniversityName.message}</small>
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li>
            <div className="content">
              <div className="career-number">
                <strong>4</strong>
              </div>
              <h3 className="pt-2 text-dark">Upload Resume</h3>
            </div>
            <div className="contact-form">
              <div className="row">
                <div className="form-group">
                  <label className="text-dark">Resume *</label>
                  <input
                    type="file"
                    accept=".pdf, .doc, .docx"
                    name="name"
                    className="form-control"
                    {...register("Resume", {
                      required: "Please upload resume.",
                    })}
                  />
                  {errors.Resume && (
                    <span className="text-danger">
                      <small>{errors.Resume.message}</small>
                    </span>
                  )}
                  {resumeValidation && (
                    <div className={alertClass} role="alert">
                      {msg}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </li>
          <li>
            <div className="col-lg-12 col-md-12 text-center">
              {loading ? (
                <div className="loader"></div>
              ) : (
                <button
                  type="submit"
                  className="default-btn btn-bg-one border-radius-50"
                >
                  Submit<i className="bx bx-chevron-right"></i>
                </button>
              )}
            </div>
          </li>
          {isShow && (
            <div className={alertClass} role="alert">
              {msg}
            </div>
          )}
        </form>
      </ul>
    </>
  );
}
