import React from 'react'
import DetailSideBar from '../common/DetailSideBar'
import HeaderBanner from '../common/HeaderBanner'

export default function InspireBusiness() {
  return (
    <>
      <HeaderBanner pageName="Inspire Business" />

      <div className="service-details-area pt-100 pb-70">
        <div className="container">
          <div className="row justify-both">
            <div className="col-lg-8">
              <div className="service-article">
                <div className="service-article-img">
                  <a href="http://inspirebusiness.in" target='_blank' rel="noreferrer">
                    <img src="assets/images/case-study/ib.png" alt="Images" className="w-100" />
                  </a>
                </div>
                <div className="service-article-content">
                  <h2>Inspire Business</h2>
                  <h3>Why Go With Inspire Business...?</h3>
                  <h5>Simple, Easy to Use, and Free Digital Business Card Design for Any Business</h5>
                  <div className="col-lg-12 col-md-12">
                    <ul className="service-article-list">
                      <li><i className="bx bxs-check-circle"></i>Simple:<br />
                        <p>The Inspire Business Digital card is simple and easy to set up. It requires only one step to join and verify your identity.</p></li>
                      <li><i className="bx bxs-check-circle"></i>Easy to Use:<br />
                        <p>It is very easy to operate for everyone. You can add your business features into it and make share at any time with your customers. Your Business Card is available to everyone for free.</p></li>
                      <li><i className="bx bxs-check-circle"></i>Free Digital Business Card:<br />
                        <p>Inspire Business Digital card is free for lifetime and use, you just need to setup it at once. Whereas you just need to add appropriate service while creating it first time.</p></li>
                    </ul>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <ul className="service-article-list service-article-rs">
                        <li><i className="bx bxs-check-circle"></i>Simple</li>
                        <li><i className="bx bxs-check-circle"></i>Easy to Use</li>
                        <li><i className="bx bxs-check-circle"></i>Free Digital Business Card</li>
                      </ul>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <ul className="service-article-list">
                        <li><i className="bx bxs-check-circle"></i>Business Growth</li>
                        <li><i className="bx bxs-check-circle"></i>Online Presence</li>
                        <li><i className="bx bxs-check-circle"></i>Offers Variety in Experience</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="service-article-another">
                  <h2>What Benefit You Will Get</h2>
                  <p>Create a digital business card for your business and make it digital forever. It's Free...🤓</p>
                  <div className="service-article-content">
                    <div className="col-lg-12 col-md-12">
                      <ul className="service-article-list">
                        <li><i className="bx bxs-check-circle"></i>Cost-Effective Exposure:<br />
                          <p>Inspire Business, the advantages accruing from listing in these directories are at nil or negligible cost. Online listings provide access to millions of potential customers to whom a business can showcase its services or products without spending as much as other marketing tactics like paid advertisements.</p></li>
                        <li><i className="bx bxs-check-circle"></i>Boost in Online Presence:<br />
                          <p>Today, when consumers require information on a certain kind of service or product, they predominantly search for the information online. Surveys show that one in three people will use their smartphone to find the right business that fits their needs and 93% of consumers use the internet to find a local business.</p>
                          <p>Online business directories provide direct exposure to potential viewers when they visit the online directory. This increases the probability that a listed business will appear on search engine result pages, and consequently increases the chances of more people knowing about the business and visiting the business website.
                            <br />More traffic translates to more visibility and a potential increase in sales.</p></li>
                        <li><i className="bx bxs-check-circle"></i>Increased Web Traffic:<br />
                          <p>Studies have found that “organic search drives 51% of all visitors to B2B and B2C websites, trumping all other non-organic search channels, including paid search (10%) and social (5%).” Organic search, driven purely by SEO and visitors’ search habits, is highly influenced by trusted business listings. <br />By rolling business listings into your company’s SEO, keywords, and backlink strategy, you can leverage this additional pathway to drive potential customers to your site and social media.</p></li>
                        <li><i className="bx bxs-check-circle"></i>Improved Online Ranking and Visibility:<br />
                          <p>Business listings increase the search engine visibility for a business. For example, when a business uses similar keywords as the business listing/directory, there is a higher likelihood that potential customers will find the business profile. The business profile will, also, subsequently push your company’s site higher in the SERPs.<br />
                            Local SEO (search engine optimization) ensures that more local leads find the business, with surveys showing that 80% of local searches result in conversions, and 28% of local searches result in a purchase.<br />
                            Business listings also provide high-quality backlists to the businesses listed as they are considered reliable and well-known sources by search engines.<br />
                            In addition, the presence of business information that is accurate and consistent leads to search engines deeming the business to be more reliable and trustworthy, thus ensuring that it is easily found during searches by potential customers. For instance, “the more complete and accurate a Google My Business listing is, the higher it will rank in Google Search and Maps, getting 7 times more clicks than those missing information.”</p></li>
                        <li><i className="bx bxs-check-circle"></i>Increased Sales:<br />
                          <p>By listing in business directories, businesses can increase sales.<br />
                            For example, according to Yelp, 97% of people spend money with a business they find on Yelp, with 51% of users transacting with the business they find within a day, and 90% transacting within a week.<br />
                            In the case of Google, customers who find a brand “through a Google My Business listing are up to 50% more likely to make a purchase.”</p></li>
                        <li><i className="bx bxs-check-circle"></i>Reaching Targeted Customer Groups Faster:<br />
                          <p>Online directories make it easier for consumers to sort through business listings by such parameters as location or field of service, making it simple for businesses and customers to connect. Local business listings serve as a way to introduce consumers to a “variety of companies offering the kind of service or product they need, based on the potential customers’ area.”<br />
                            According to studies, 82% of smartphone users who used their devices to help them shop were able to refine their searches by location and industry/sector by conducting “near me” searches for food: 84%, entertainment: 56%, banking: 50%, apparel: 41%, and personal care: 38%. Consumers were also able to search for a specific store/retailer: 48%, a product category nearby: 29%, and a specific brand nearby: 23%.</p></li>
                        <li><i className="bx bxs-check-circle"></i>Building Business Reputation and Trust:<br />
                          <p>Appearing in trusted business listings endears a business to potential customers, as online directories show that the business is “not a fraud or a fake company hiding behind a website.”<br />
                            For example, businesses that list in such directories as Google My Business are considered credible by consumers, due to the painstaking process that a business has to go through to be listed. Google is also a trusted search engine with over 3.5 billion searches carried out on it each day.<br />
                            Additionally, listings provide the ability for businesses to build their profiles through testimonials from customers’ reviews and ratings. Positive reviews and high ratings build a trusted and credible reputation for a business.</p></li>
                      </ul>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 col-sm-6">
                      <div className="service-article-another-img">
                        <img src="assets/images/products/inspire-business/inspire-business-1.jpg" alt="Images" className='p-img' />
                      </div>
                    </div>
                    <div className="col-lg-6 col-sm-6">
                      <div className="service-article-another-img">
                        <img src="assets/images/products/inspire-business/inspire-business-2.png" alt="Images" className='p-img' />
                      </div>
                    </div>
                  </div>
                  <p>Online directories have existed for many years and are now quickly gaining popularity as a quick way for consumers to connect with businesses and review product and service offerings rather quickly. Let’s discuss the top seven reasons why you need to get your business listed online as soon as possible.</p>
                </div>
                <div className="service-work-process">
                  <h2>Our Working Process</h2>
                  <p>Accurate listings create the opportunity for transparency between businesses and consumers in the form of reviews, and the availability of these accurate listings will help increase a business's visibility.<br />
                    Business directories collate and present the key information of the businesses listed within the directory in a readily digestible format. The purpose of business directories is to provide the user base with the information they need to become aware of, contact, locate and otherwise interact with businesses.</p>
                </div>
              </div>
            </div>
            <DetailSideBar data='product' />
          </div>
        </div>
      </div>
    </>
  )
}
