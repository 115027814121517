import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Service from "../../Services/Service";
import Loader from "../../Services/Loader";
import { SHA256 } from "crypto-js";

export function Cart() {
  let [product, setProduct] = useState([]);
  let { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);

  const edumaster = [
    {
      title: "EduMaster",
      desc1: "Education",
      desc2: "Exam",
      image: "assets/images/case-study/edumaster.png",
      path: "/edumaster",
      price: "49,999",
    },
  ];
  const inspirebusiness = [
    {
      title: "Inspire Business",
      desc1: "Business",
      desc2: "Marketing",
      image: "assets/images/case-study/ib.png",
      path: "/inspire-business",
      price: "11,999",
    },
  ];
  const atbeloved = [
    {
      title: "ATBELOVED",
      desc1: "Family",
      desc2: "Connective",
      image: "assets/images/case-study/atbeloved.png",
      path: "/atbeloved",
      price: "15,999",
    },
  ];
  const atopd = [
    {
      title: "ATOPD",
      desc1: "Health",
      desc2: "Medical",
      image: "assets/images/case-study/atopd.png",
      path: "/atopd",
      price: "21,999",
    },
  ];
  const matrimony = [
    {
      title: "Matrimony",
      desc1: "Social Work",
      desc2: "Help",
      image: "assets/images/case-study/mkvlvivah.png",
      path: "/matrimony",
      price: "28,999",
    },
  ];
  const lms = [
    {
      title: "Leave Management System",
      desc1: "Educational",
      desc2: "Office",
      image: "assets/images/case-study/lms.png",
      path: "/lms",
      price: "49,999",
    },
  ];
  const ecom = [
    {
      title: "eCommerce Solution",
      desc1: "Marketing",
      desc2: "Office",
      image: "assets/images/case-study/ecom.jpg",
      path: "/ecom",
      price: "23,999",
    },
  ];
  const turf = [
    {
      title: "Turf Booking",
      desc1: "Games",
      desc2: "Indoor & Outdoor Games",
      image: "assets/images/case-study/turf.jpg",
      path: "/turf",
      price: "34,999",
    },
  ];

  const sheleads = [
    {
      title: "Sheleads India",
      desc1: "Business",
      desc2: "Multivendor Ecommerce",
      image: "assets/images/case-study/sheleads.png",
      path: "/sheleads",
      price: "21,999",
    },
  ];

  const lingayat = [
    {
      title: "Lingayat Vadhu-Var Matrimony",
      desc1: "Social Work",
      desc2: "Help",
      image: "assets/images/case-study/lingayat.png",
      path: "/lingayat-vadhu-var",
      price: "21,999",
    },
  ];

  useEffect(() => {
    if (id === "edumaster") {
      setProduct(edumaster);
    } else if (id === "inspire-business") {
      setProduct(inspirebusiness);
    } else if (id === "atbeloved") {
      setProduct(atbeloved);
    } else if (id === "atopd") {
      setProduct(atopd);
    } else if (id === "matrimony") {
      setProduct(matrimony);
    } else if (id === "lms") {
      setProduct(lms);
    } else if (id === "ecom") {
      setProduct(ecom);
    } else if (id === "turf") {
      setProduct(turf);
    } else if (id === "sheleads") {
      setProduct(sheleads);
    } else if (id === "lingayat-vadhu-var") {
      setProduct(lingayat);
    }
  }, [id]);

  const payNow = async () => {
    setIsLoading(true);
    var rate = product[0].price;
    rate = rate.replace(/\,/g, ""); // 1125, but a string, so co  nvert it to number
    rate = parseInt(rate, 10);
    var saltKey = "0d8b9f31-5d27-48a7-9405-d83f80d2944a";
    var data = {
      merchantId: "ATJOINONLINE",
      merchantTransactionId: Math.random().toString(36).slice(2).toUpperCase(),
      merchantUserId: "bhorkadear@gmail.com",
      amount: rate * 100,
      redirectUrl: window.location.origin + "/order-status",
      redirectMode: "REDIRECT",
      callbackUrl: window.location.origin + "/order-status",
      mobileNumber: "8275582215",
      paymentInstrument: {
        type: "PAY_PAGE",
      },
    };

    const base64 = window.btoa(JSON.stringify(data));
    var saltIndex = 1;
    var string = base64 + "/pg/v1/pay" + saltKey;
    var sha256 = SHA256(string).toString();
    var xVerify = sha256 + "###" + saltIndex;
    localStorage.setItem("PaymentDetails", JSON.stringify(data));
    Service.payNow(base64, xVerify)
      .then((res) => {
        console.log(res.data);
        window.location.href = res.data.data.instrumentResponse.redirectInfo.url;
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  return (
    <div className="container">
      {isLoading && <Loader />}
      <table className="table">
        <thead>
          <tr className="text-center">
            <th>#</th>
            <th>Image</th>
            <th>Product Name</th>
            <th>Price</th>
            <th>Remove</th>
          </tr>
        </thead>
        <tbody>
          {product.map((item, i) => (
            <tr className="fw-bold" key={i}>
              <th>1</th>
              <td className="w-25">
                <img src={item.image.replace('https://atjoin.in/cart/', 'https://atjoin.in/')} alt={item.title} />
                {/* <img src={item.image.replace('http://localhost:3000/cart', 'http://localhost:3000/')} alt={item.title} /> */}
              </td>
              <td className="px-5">
                {item.title} ({item.desc1})<p>{item.desc2}</p>
              </td>
              <td className="text-center">{"₹ " + item.price}</td>
              <td className="text-center">
                <Link to="/products">
                  <button type="button" className="btn-close"></button>
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="card my-5 ms-auto" style={{ width: "18rem" }}>
        <div className="card-header h6 text-dark">Order Summary</div>
        {product.map((item, i) => (
          <ul className="list-group list-group-flush">
            <li className="list-group-item d-flex justify-content-between">
              <div>Sub Total : </div>
              <div className="h6">{"₹ " + item.price}</div>
            </li>
            <li className="list-group-item d-flex justify-content-between">
              <div>Discount : </div>
              <div className="h6">₹ 0</div>
            </li>
            <li className="list-group-item d-flex justify-content-between">
              <div>Other Charges : </div>
              <div className="h6">₹ 0</div>
            </li>
            <li className="list-group-item d-flex justify-content-between">
              <div>Total : </div>
              <div className="h5">{"₹ " + item.price}</div>
            </li>
          </ul>
        ))}
        <div className="card-footer">
          <button onClick={payNow} className="btn btn-warning w-100">
            Checkout
          </button>
        </div>
      </div>
    </div>
  );
}
