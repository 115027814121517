import React from 'react'
import DetailSideBar from '../common/DetailSideBar'
import HeaderBanner from '../common/HeaderBanner'

export default function InspireBusiness() {
  return (
    <>
      <HeaderBanner pageName="eCommerce Solution" />

      <div className="service-details-area pt-100 pb-70">
        <div className="container">
          <div className="row justify-both">
            <div className="col-lg-8">
              <div className="service-article">
                <div className="service-article-img">
                  <a href="https://demo.sheleadsindia.co.in/" target='_blank' rel="noreferrer">
                    <img src="assets/images/case-study/ecom.jpg" alt="Images" className="w-100" />
                  </a>
                </div>
                <div className="service-article-content">
                  <h2>eCommerce Solution</h2>
                  <h3>Why Go With eCommerce Solution...?</h3>
                  <h6>Simple, Easy to Use, and Digital Solution for Any Business</h6>
                  <div className="col-lg-12 col-md-12">
                    <ul className="service-article-list">
                      <li><i className="bx bxs-check-circle"></i>Simple:<br />
                        <p>With an eCommerce Solution, Design a user-friendly menu structure that makes it easy for customers to explore different product categories..</p></li>
                      <li><i className="bx bxs-check-circle"></i>Easy to Use:<br />
                        <p>eCommerce Solution allow customers to see essential product information without leaving the category page..</p></li>
                      <li><i className="bx bxs-check-circle"></i>Unlimited Products & Varients:<br />
                        <p>eCommerce Solution Allow products with various options (sizes, colors, etc.) to be managed under a single listing.</p></li>
                    </ul>
                  </div>
                  <div className="row">
                    <div className="col-lg-4 col-md-12">
                      <ul className="service-article-list service-article-rs">
                        <li><i className="bx bxs-check-circle"></i>Simple</li>
                        <li><i className="bx bxs-check-circle"></i>Easy to Use</li>
                        <li><i className="bx bxs-check-circle"></i>Product Listings and Search</li>
                      </ul>
                    </div>
                    <div className="col-lg-4 col-md-12">
                      <ul className="service-article-list">
                        <li><i className="bx bxs-check-circle"></i>Time Saving</li>
                        <li><i className="bx bxs-check-circle"></i>Online Presence</li>
                        <li><i className="bx bxs-check-circle"></i>Offers Variety in Experience</li>
                      </ul>
                    </div>
                    <div className="col-lg-4 col-md-12">
                      <ul className="service-article-list">
                        <li><i className="bx bxs-check-circle"></i>Shopping Cart</li>
                        <li><i className="bx bxs-check-circle"></i>Checkout Process</li>
                        <li><i className="bx bxs-check-circle"></i>Inventory Management</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="service-article-another">
                  <h2>What Benefit You Will Get</h2>
                  <p>A eCommerce Solution offers several advantages for your business....🤓</p>
                  <div className="service-article-content">
                    <div className="col-lg-12 col-md-12">
                      <ul className="service-article-list">
                        <li><i className="bx bxs-check-circle"></i>Product Listings and Search:<br />
                          <p>Display products with images, descriptions, prices, and availability. Also provide filtering and sorting options to help customers find products quickly.</p></li>
                        <li><i className="bx bxs-check-circle"></i>Shopping Cart:<br />
                          <p>Allow customers to add and manage items before proceeding to checkout. Display the total order amount, including taxes and shipping costs.</p></li>
                        <li><i className="bx bxs-check-circle"></i>Responsive Design:<br />
                          <p>Ensure the eCommerce site is accessible and functional on various devices (desktops, tablets, smartphones).</p></li>
                        <li><i className="bx bxs-check-circle"></i>Order Tracking and Notifications:<br />
                          <p>Send order confirmation emails and shipping notifications. Provide tracking links so customers can monitor their orders' progress.</p></li>
                        <li><i className="bx bxs-check-circle"></i>Intuitive Navigation:<br />
                          <p>Design a user-friendly menu structure that makes it easy for customers to explore different product categories.</p></li>
                        <li><i className="bx bxs-check-circle"></i>User-Friendly Dashboard:<br />
                          <p>Provide a simple and clean admin dashboard for store owners to manage products, orders, and settings.</p></li>
                        <li><i className="bx bxs-check-circle"></i>Inventory Management:<br />
                          <p>Track product stock levels and automatically update availability status based on sales.</p></li>
                        <li><i className="bx bxs-check-circle"></i>Real-time Analytics and Reports:<br />
                          <p>Provide store owners with insights into sales, traffic, and customer behavior through easy-to-understand dashboards.</p></li>
                      </ul>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 col-sm-6">
                      <div className="service-article-another-img">
                        <img src="assets/images/products/ecom/ecom-1.jpg" alt="Images" className='p-img' />
                      </div>
                    </div>
                    <div className="col-lg-6 col-sm-6">
                      <div className="service-article-another-img">
                        <img src="assets/images/products/ecom/ecom-2.jpg" alt="Images" className='p-img' />
                      </div>
                    </div>
                  </div>
                  <p>A eCommerce Solution offers numerous advantages, including process efficiency, cost savings, accuracy, transparency, compliance and data-driven decision-making. Implementing an eCommerce Solution can greatly benefit organizations by streamlining management and improving overall operational effectiveness.</p>
                </div>
                <div className="service-work-process">
                  <h2>Our Working Process</h2>
                  <p>An eCommerce solution is a comprehensive digital platform designed to facilitate online buying and selling transactions. It empowers businesses and individuals to showcase their products or services on the internet, enabling customers to browse, select, and purchase items conveniently from the comfort of their devices. This solution encompasses a wide range of features and tools that collectively create a seamless and efficient online shopping experience for both buyers and sellers.</p>
                </div>
              </div>
            </div>
            <DetailSideBar data='eCommerce Solution' />
          </div>
        </div>
      </div>
    </>
  )
}
