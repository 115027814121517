import React from 'react'
import { Link } from 'react-router-dom'

function DetailSideBar(data) {

  return (
    <div className="col-lg-4">
      <div className="side-bar-area">
        <div className="side-bar-widget">
          <h3 className="title">
            {data.data === "service" ? "Our Services" : "Our Products"}
          </h3>
          <div className="side-bar-categories">
            {data.data === "service" ? (
              <ul>
                <li>
                  <div className="line-circle"></div>
                  <Link to="/it-cosulting" rel="noreferrer">
                    IT Services
                  </Link>
                </li>
                <li>
                  <div className="line-circle"></div>
                  <Link to="/app-dev" rel="noreferrer">
                    Mobile Application Development
                  </Link>
                </li>
                <li>
                  <div className="line-circle"></div>
                  <Link to="/web-dev" rel="noreferrer">
                    Web Development
                  </Link>
                </li>
                <li>
                  <div className="line-circle"></div>
                  <Link to="/business" rel="noreferrer">
                    Business Reform
                  </Link>
                </li>
                <li>
                  <div className="line-circle"></div>
                  <Link to="/infrastructure" rel="noreferrer">
                    Infrastructure
                  </Link>
                </li>
                <li>
                  <div className="line-circle"></div>
                  <Link to="/data-analysis" rel="noreferrer">
                    Data Analysis
                  </Link>
                </li>
              </ul>
            ) : (
              <ul>
                <li>
                  <div className="line-circle"></div>
                  <Link to="/edumaster" rel="noreferrer">
                    EduMaster
                  </Link>
                </li>
                <li>
                  <div className="line-circle"></div>
                  <Link to="/inspire" rel="noreferrer">
                    Inspire Business
                  </Link>
                </li>
                <li>
                  <div className="line-circle"></div>
                  <Link to="/atbeloved" rel="noreferrer">
                    ATBELOVED
                  </Link>
                </li>
                <li>
                  <div className="line-circle"></div>
                  <Link to="/atopd" rel="noreferrer">
                    ATOPD
                  </Link>
                </li>
                <li>
                  <div className="line-circle"></div>
                  <Link to="/matrimony" rel="noreferrer">
                    Matrimony
                  </Link>
                </li>
                <li>
                  <div className="line-circle"></div>
                  <Link to="/lms" rel="noreferrer">
                    Leave Management System
                  </Link>
                </li>
                <li>
                  <div className="line-circle"></div>
                  <Link to="/lingayat-vadhu-var" rel="noreferrer">
                    Lingayat Vadhu-Var Matrimony
                  </Link>
                </li>
              </ul>
            )}
          </div>
        </div>
        {/* <div className="side-bar-widget">
          <h3 className="title">Tag Cloud</h3>
          <ul className="side-bar-widget-tag">
            <li><Link to="/" rel="noreferrer">Android</Link></li>
            <li><Link to="/" rel="noreferrer">Creative</Link></li>
            <li><Link to="/app-dev" rel="noreferrer">App</Link></li>
            <li><Link to="/" rel="noreferrer">iOS</Link></li>
            <li><Link to="/business" rel="noreferrer">Business</Link></li>
            <li><Link to="/it-cosulting" rel="noreferrer">Consulting</Link></li>
          </ul>
        </div> */}
        {/* <div className="side-bar-widget">
          <h3 className="title">Gallery</h3>
          <ul className="blog-gallery">
            <li>
              <Link to="https://www.instagram.com/" rel="noreferrer">
                <img src="assets/images/blog/blog-small-img1.jpg" alt="ATJOIN PVT. LTD." />
              </Link>
            </li>
            <li>
              <Link to="https://www.instagram.com/" rel="noreferrer">
                <img src="assets/images/blog/blog-small-img2.jpg" alt="ATJOIN PVT. LTD." />
              </Link>
            </li>
            <li>
              <Link to="https://www.instagram.com/" rel="noreferrer">
                <img src="assets/images/blog/blog-small-img3.jpg" alt="ATJOIN PVT. LTD." />
              </Link>
            </li>
            <li>
              <Link to="https://www.instagram.com/" rel="noreferrer">
                <img src="assets/images/blog/blog-small-img4.jpg" alt="ATJOIN PVT. LTD." />
              </Link>
            </li>
            <li>
              <Link to="https://www.instagram.com/" rel="noreferrer">
                <img src="assets/images/blog/blog-small-img5.jpg" alt="ATJOIN PVT. LTD." />
              </Link>
            </li>
            <li>
              <Link to="https://www.instagram.com/" rel="noreferrer">
                <img src="assets/images/blog/blog-small-img6.jpg" alt="ATJOIN PVT. LTD." />
              </Link>
            </li>
          </ul>
        </div> */}
        {/* <div className="side-bar-widget">
          <h3 className="title">Archive</h3>
          <div className="side-bar-categories">
            <ul>
              <li>
                <div className="line-circle"></div>
                <Link to="/" rel="noreferrer">Design<span>[70]</span></Link>
              </li>
              <li>
                <div className="line-circle"></div>
                <Link to="/business" rel="noreferrer">Business<span>[24]</span></Link>
              </li>
              <li>
                <div className="line-circle"></div>
                <Link to="/web-dev" rel="noreferrer">Development<span>[08]</span></Link>
              </li>
              <li>
                <div className="line-circle"></div>
                <Link to="/" rel="noreferrer">Technology <span>[17]</span></Link>
              </li>
              <li>
                <div className="line-circle"></div>
                <Link to="/" rel="noreferrer">Startup <span>[20]</span></Link>
              </li>
              <li>
                <div className="line-circle"></div>
                <Link to="/" rel="noreferrer">Marketing Growth<span>[13]</span></Link>
              </li>
            </ul>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default DetailSideBar