import React from "react";
import DetailSideBar from "../common/DetailSideBar";
import HeaderBanner from "../common/HeaderBanner";

export default function Infrastructure() {
  return (
    <>
      <HeaderBanner pageName="Infrastructure" />

      <div className="service-details-area pt-100 pb-70">
        <div className="container">
          <div className="row justify-both">
            <div className="col-lg-8">
              <div className="service-article">
                <div className="service-article-img">
                  <img src="assets/images/services/Infrastructure/banner.jpg" alt="Images" className="w-100" />
                </div>
                <div className="service-article-content">
                  <h2>Infrastructure</h2>
                  <p>The term infrastructure (or "below the structure") is widely used for public works such as water and electric delivery
                    systems, roads, and bridges and, even more generally, to refer to any systems that support society or an
                    organization. IT infrastructure is the stuff on which we build and operate IT applications. Note that while people
                    are specifically excluded from this definition, the costs of people to implement and maintain infrastructure are included
                    in analyzing IT infrastructure projects.</p>
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <ul className="service-article-list service-article-rs">
                        <li><i className="bx bxs-check-circle"></i>Maximize Your Revenue</li>
                        <li><i className="bx bxs-check-circle"></i>New technology acceptance</li>
                        <li><i className="bx bxs-check-circle"></i>Boosts Productivity</li>
                      </ul>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <ul className="service-article-list">
                        <li><i className="bx bxs-check-circle"></i>Maintaining prior infrastructure</li>
                        <li><i className="bx bxs-check-circle"></i>Basic capabilities</li>
                        <li><i className="bx bxs-check-circle"></i>Offers Variety in Experience</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="service-article-another">
                  <h2>What Benefit You Will Get</h2>
                  <p>One of the more well-known benefits of IaaS involves increased performance. With IaaS technology, your
                    infrastructure is provided and supported by the IaaS cloud service provider of your choice. Typically, a cloud provider
                    has a more reliable, redundant, and robust infrastructure set-up than what would be feasible and financially realistic
                    in an office environment. This means that you can save your business the upfront costs associated with purchasing,
                    maintaining, and operating hardware, decreasing overall capital expenditure (CapEx) for your IT spend. It also means
                    that you can achieve a higher performance level from your infrastructure. With an IaaS cloud service provider, your
                    Service Level Agreement (SLA) can ensure that you are getting the best performance from your cloud provider's
                    infrastructure. This holds your cloud provider accountable for continuous upgrades on their end to provide the best
                    possible service to you and your business. With IaaS technology, your in-house IT team can spend more time
                    focusing on how they can help further your business goals and objectives through technology rather than buying the
                    latest hardware for your infrastructure.</p>
                  <div className="row">
                    <div className="col-lg-6 col-sm-6">
                      <div className="service-article-another-img">
                        <img src="assets/images/services/Infrastructure/img1.jpg" alt="Images" className="p-img" />
                      </div>
                    </div>
                    <div className="col-lg-6 col-sm-6">
                      <div className="service-article-another-img">
                        <img src="assets/images/services/Infrastructure/img2.jpg" alt="Images" className="p-img" />
                      </div>
                    </div>
                  </div>
                  <p>Scalability and flexibility are important advantages of IaaS. With IaaS technology, your business can scale up and
                    down on an on-demand and as-needed basis. For example, say your business hosts a national digital campaign that drives
                    traffic to your website over a relatively short period. IaaS ensures your business infrastructure can handle the boost in
                    traffic to your home page through procedures such as the automatic provisioning of resources.</p>
                </div>
                <div className="service-work-process">
                  <h2>Our Working Process</h2>
                  <p>The components of IT infrastructure are made up of interdependent elements, and the two core groups of
                    components are hardware and software. Hardware uses software—like an operating system—to work. And likewise, an
                    operating system manages system resources and hardware. Operating systems also make connections between software
                    applications and physical resources using networking components.</p>
                </div>
              </div>
            </div>
            <DetailSideBar data='service' />
          </div>
        </div>
      </div>
    </>
  );
}
