import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
// import { Link } from "react-router-dom";
import HeaderBanner from "../common/HeaderBanner";
import Service from "../Services/Service";
// import { useNavigate } from "react-router-dom";
import Loader from "../Services/Loader";

export default function Internship() {
  // const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [alertClass, setAlertClass] = useState();
  const [msg, setMsg] = useState();
  // const [transactionID, setTransactionID] = useState();
  const [internshipPackages, setInternshipPackages] = useState();
  const [technologyList, setTechnologyList] = useState();
  const [internshipMode, setInternshipMode] = useState();
  const [paymentType, setPaymentType] = useState();
  const [paymentLink, setPaymentLink] = useState();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  useEffect(() => {
    loadInternshipPackages();
  }, []);

  const loadInternshipPackages = () => {
    setIsLoading(true);
    Service.getInternshipPackages()
      .then((res) => {
        setInternshipPackages(res.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const getTechnologies = (id) => {
    setIsLoading(true);
    Service.getTechnologies(id)
      .then((res) => {
        setTechnologyList(res.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const getInternshipMode = (id) => {
    setIsLoading(true);
    Service.getInternshipMode(id)
      .then((res) => {
        setInternshipMode(res.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const getPaymentType = (id) => {
    setIsLoading(true);
    Service.getPaymentType(id)
      .then((res) => {
        setPaymentType(res.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const saveData = (data) => {
    setIsLoading(true);
    let fd = new FormData();
    fd.append("Name", data.Name);
    fd.append("Contact", data.Contact);
    fd.append("Email", data.Email);
    fd.append("IPId", data.IPId);
    fd.append("PDId", data.PDId);
    fd.append("IMId", data.IMId);
    fd.append("IPTId", data.IPTId);
    fd.append("Resume", data.Resume[0]);
    Service.applyForInternship(fd)
      .then((res) => {
        setIsLoading(false);
        if (res.data.success) {
          setIsShow(true);
          setAlertClass("alert alert-success");
          setMsg(res.data.success);
          alert(
            "Success. Thank you for showing interest in ATJOIN PVT. LTD. \n\nPlease pay the fees for book your seat..!"
          );
          reset();
          setIsShow(false);
          window.location.replace(paymentLink);
        } else {
          setIsLoading(false);
          setIsShow(true);
          setAlertClass("alert alert-danger");
          setMsg(res.data.warning);
          alert("Failure. Contact no already registered..!");
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const onPackageChange = (e) => {
    if (e.target.value) {
      setTechnologyList(null);
      setInternshipMode(null);
      setPaymentType(null);
      getTechnologies(e.target.value);
    }
  };

  const onTechnologyChange = (e) => {
    if (e.target.value) {
      setInternshipMode(null);
      setPaymentType(null);
      getInternshipMode(e.target.value);
    }
  };

  const onModeChange = (e) => {
    if (e.target.value) {
      setPaymentType(null);
      getPaymentType(e.target.value);
    }
  };

  const onPaymentTypeChange = (e) => {
    var result = paymentType.filter((item) => item.IPTId === e.target.value);
    setPaymentLink(result[0].PaymentLink);
  };

  return (
    <>
      <HeaderBanner pageName="Apply for internship" />
      {isLoading && <Loader />}
      <div className="contact-form-area pt-100 pb-70">
        <div className="container">
          <div className="section-title text-center">
            <h2>Apply For Internship</h2>
          </div>
          <div className="row pt-45">
            <div className="col-lg-12">
              {isShow && (
                <div className={alertClass} role="alert">
                  {msg}
                </div>
              )}
              <div className="contact-form">
                <form onSubmit={handleSubmit(saveData)}>
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="form-group">
                        <label>
                          Candidate Name <span>*</span>
                        </label>
                        <input
                          {...register("Name", {
                            required: "Enter candidate name.",
                          })}
                          type="text"
                          className="form-control"
                          placeholder="Candidate name"
                        />
                        {errors.Name && (
                          <p className="text-danger">{errors.Name.message}</p>
                        )}
                      </div>
                    </div>

                    <div className="col-lg-4">
                      <div className="form-group">
                        <label>
                          Contact No (Prefer WhatsApp No.) <span>*</span>
                        </label>
                        <input
                          {...register("Contact", {
                            required: "Enter contact no.",
                            pattern: {
                              value: /^(0|[1-9]\d*)(\.\d+)?$/,
                              message: "Enter valid contact no.",
                            },
                            minLength: {
                              value: 10,
                              message: "Enter minimum 10 digit.",
                            },
                            maxLength: {
                              value: 10,
                              message: "Enter maximum 10 digit.",
                            },
                          })}
                          className="form-control"
                          maxLength={10}
                          placeholder="Contact number"
                        />
                        {errors.Contact && (
                          <p className="text-danger">
                            {errors.Contact.message}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <label>
                          Your Email <span>*</span>
                        </label>
                        <input
                          {...register("Email", {
                            required: "Enter email id.",
                            pattern: {
                              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                              message: "Enter valid email",
                            },
                          })}
                          className="form-control"
                          placeholder="Email address"
                        />
                        {errors.Email && (
                          <p className="text-danger">{errors.Email.message}</p>
                        )}
                      </div>
                    </div>

                    <div className="col-lg-4">
                      <div className="form-group">
                        <div>
                          <label>
                            Select Internship Package <span>*</span>
                          </label>
                        </div>
                        {internshipPackages && (
                          <select
                            className="form-select"
                            aria-label="Default select example"
                            {...register("IPId", {
                              required: "Select internship package",
                            })}
                            onChange={onPackageChange}
                          >
                            <option value="" selected>
                              Select Internship Package
                            </option>
                            {internshipPackages &&
                              internshipPackages.map((item, i) => (
                                <option key={i} value={item.IPId}>
                                  {item.Name}
                                </option>
                              ))}
                          </select>
                        )}

                        {errors.IPId && (
                          <p className="text-danger">{errors.IPId.message}</p>
                        )}
                      </div>
                    </div>

                    {technologyList && (
                      <div className="col-lg-4">
                        <div className="form-group">
                          <div>
                            <label>
                              Select Technology/Language <span>*</span>
                            </label>
                          </div>

                          <select
                            className="form-select"
                            aria-label="Default select example"
                            {...register("PDId", {
                              required: "Select technology",
                            })}
                            onChange={onTechnologyChange}
                          >
                            <option value="" selected>
                              Select Technology
                            </option>
                            {technologyList &&
                              technologyList.map((item, i) => (
                                <option key={i} value={item.PDId}>
                                  {item.Technology}
                                </option>
                              ))}
                          </select>

                          {errors.PDId && (
                            <p className="text-danger">{errors.PDId.message}</p>
                          )}
                        </div>
                      </div>
                    )}

                    {internshipMode && (
                      <div className="col-lg-4">
                        <div className="form-group">
                          <div>
                            <label>
                              Mode of Internship <span>*</span>
                            </label>
                          </div>

                          <select
                            className="form-select"
                            aria-label="Default select example"
                            {...register("IMId", {
                              required: "Select technology",
                            })}
                            onChange={onModeChange}
                          >
                            <option value="" selected>
                              Select Mode
                            </option>
                            {internshipMode &&
                              internshipMode.map((item, i) => (
                                <option key={i} value={item.IMId}>
                                  {item.Mode}
                                </option>
                              ))}
                          </select>

                          {errors.IMId && (
                            <p className="text-danger">{errors.IMId.message}</p>
                          )}
                        </div>
                      </div>
                    )}

                    {paymentType && (
                      <div className="col-lg-4">
                        <div className="form-group">
                          <div>
                            <label>
                              Payment Type <span>*</span>
                            </label>
                          </div>

                          <select
                            className="form-select"
                            aria-label="Default select example"
                            {...register("IPTId", {
                              required: "Select payment type",
                            })}
                            onChange={onPaymentTypeChange}
                          >
                            <option value="" selected>
                              Select payment type
                            </option>
                            {paymentType &&
                              paymentType.map((item, i) => (
                                <option key={i} value={item.IPTId}>
                                  {item.Type}
                                </option>
                              ))}
                          </select>

                          {errors.IPTId && (
                            <p className="text-danger">
                              {errors.IPTId.message}
                            </p>
                          )}
                        </div>
                      </div>
                    )}

                    <div className="col-lg-4">
                      <div className="form-group">
                        <label>
                          Upload Resume <span>*</span>
                        </label>
                        <input
                          {...register("Resume", {
                            required: "Upload updated Resume.",
                          })}
                          type="file"
                          className="form-control"
                          accept="application/pdf"
                        />
                        {errors.Resume && (
                          <p className="text-danger">{errors.Resume.message}</p>
                        )}
                      </div>
                    </div>

                    <div className="col-lg-12 col-md-12 text-center">
                      <button
                        type="submit"
                        className="default-btn btn-bg-two border-radius-50"
                      >
                        Apply Now<i className="bx bx-chevron-right"></i>
                      </button>

                      {/* <div id="msgSubmit" className="h3 text-center hidden"></div><div className="clearfix"></div> */}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
